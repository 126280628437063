import { useEffect } from 'react';

import dispatchEvent from '../fns/common/dispatchEvent';

/**
 * @template {keyof CustomEventMap} T
 * @param {T} type
 * @param {CustomListener<T>} listener
 * @param {import("react").DependencyList} deps
 * @returns {(detail: CustomEventMap[T]) => boolean}
 */
export default function useEvent(type, listener, deps) {
  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    const frozen = (/** @type {CustomEvent<CustomEventMap[T]>} */ ev) =>
      listener(ev.detail);

    window.addEventListener(type, frozen);

    return () => {
      window.removeEventListener(type, frozen);
    };
  }, [type, listener, ...deps]);

  return (detail) => dispatchEvent(type, detail);
}
