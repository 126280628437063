import React, { Component } from 'react';
import discord from '../../../../assets/images/discord-blue.svg';
import superbowl from '../../../../assets/images/superbowl.jpg';

class Clock extends Component {
  constructor(props) {
    super(props);
    this.state = {
      days: 0,
      hours: 0,
      minutes: 0,
      seconds: 0,
    };
  }
  componentWillMount() {
    this.getTimeUntil(this.props.deadline);
  }
  componentDidMount() {
    setInterval(() => this.getTimeUntil(this.props.deadline), 1000);
  }
  leading0(num) {
    return num < 10 ? '0' + num : num;
  }
  getTimeUntil(deadline) {
    const time = Date.parse(deadline) - Date.parse(new Date());
    let days,
      hours,
      minutes,
      seconds = 0;
    if (time > 0) {
      days = Math.floor(time / (1000 * 60 * 60 * 24));
      hours = Math.floor((time / (1000 * 60 * 60)) % 24);
      minutes = Math.floor((time / 1000 / 60) % 60);
      seconds = Math.floor((time / 1000) % 60);
    }
    this.setState({ days, hours, minutes, seconds });
  }

  render() {
    return (
      <section
        className="countdown bg-dkGrey py-10 md:py-16"
        id="something-big"
      >
        <div className="px-5 md:px-10">
          <div className="flex flex-col-reverse lg:flex-row align-start countdown-container">
            <div className="w-full lg:w-5/12 xl:w-1/2">
              <img
                className="secret shadow-gray-50 rounded-lg object-cover h-[100%] mt-10 lg:mt-0"
                src={superbowl}
                alt="metafans superbowl countdown"
              ></img>
            </div>
            <div className="w-full lg:w-7/12 xl:w-1/2 countdown-info lg:ml-16">
              <h1 className="lt-white font-bold text-3xl md:text-4xl">
                It's Coming. Clubhouse is your Digital Country Club.
              </h1>
              <p className="text-grey info text-md md:text-lg mt-5">
                The MetaFans Clubhouse is almost here! If you want full access
                to all our games, prizes, and digital experiences, you will need
                a Genesis Metafan by August 15th at 8pm EST.
              </p>
              {/* Superbowl countdown */}
              <div className="launch-countdown count-two lt-white p-3 bg-dkGrey border-2 border-ltGrey rounded-xl max-w-[85%] mt-10 shadow-gray-900">
                <div className="text-center">
                  <h2>Secure your spot, Mint today.</h2>
                  <p className="launch-date accent-green mb-0 mt-3 mx-auto">
                    August 15, 2022 at 8 PM EST
                  </p>
                </div>
                <div className="flex justify-center items-center countdown-meter pl-5 pr-5 pt-3 text-center">
                  <div className="flex justify-center items-center countdown-meter pl-5 pr-5 pt-3 text-center">
                    {' '}
                    <div className="Clock-hours">
                      <h1 className="font-medium">
                        {this.leading0(this.state.days)}
                      </h1>
                      <p>days</p>
                    </div>
                    <div className="Clock-hours">
                      <h1 className="font-medium">
                        {this.leading0(this.state.hours)}
                      </h1>
                      <p>hours</p>
                    </div>
                    <div className="Clock-minutes">
                      <h1 className="font-medium">
                        {this.leading0(this.state.minutes)}
                      </h1>
                      <p>minutes</p>
                    </div>
                    <div className="Clock-seconds">
                      <h1 className="font-medium">
                        {this.leading0(this.state.seconds)}
                      </h1>
                      <p>seconds</p>
                    </div>
                  </div>
                </div>
                <div className="p-3 pt-4 text-center footer-count-notes">
                  <a
                    href="https://www.metafans.com/blog/party2023/"
                    target="_blank"
                    className="discord flex justify-center items-center"
                  >
                    <img src={discord} alt="join our discord"></img>
                    <p className="lt-white font-bold">
                      Join our discord community
                    </p>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}
export default Clock;
