import React from 'react';
import { toast } from 'react-toastify';
import Button from '../button/Button';

import injected from '../../fns/wallets/injected';
import walletConnect from '../../fns/wallets/walletConnect';

const ConnectButton = () => {
  return (
    <Button
      variant="Outline"
      className="font-bold rounded-[2rem] py-2 px-5 text-xs lg:text-sm"
      onClick={() => {
        const connector = window.ethereum ? injected : walletConnect;
        connector.connect().catch((err) => {
          toast.error(err.message);
        });
      }}
    >
      Connect Wallet
    </Button>
  );
};

export default ConnectButton;
