import React from 'react';
import { toast } from 'react-toastify';
import { isDesktop } from 'react-device-detect';
import ConnectButton from '../ConnectButton/ConnectButton';
import InstallButton from '../InstallButton/InstallButton';
import OpensaleButton from '../OpensaleButton/OpensaleButton';
import WrongChainButton from '../WrongChainButton/WrongChainButton';
import Button from '../button/Button';
import injected from '../../fns/wallets/injected';
import walletConnect from '../../fns/wallets/walletConnect';
import MetaFox from '../../assets/images/metafox.svg';
import env from '../../data/env';
import {
  useChain,
  useTotalSupply,
  useWallet,
} from '../../hooks/useEventStates';

const MetaConnect = () => {
  const account = useWallet();
  const chainId = useChain();
  const totalSupply = useTotalSupply();

  if (!(account && chainId)) {
    const isMetaMaskInstalled = !!window.ethereum?.isMetaMask;

    if (isMetaMaskInstalled) {
      return (
        <Button
          className="flex justify-center items-center bg-primary rounded-xl mx-auto min-w-[21rem]"
          onClick={() => {
            const connector = window.ethereum ? injected : walletConnect;

            connector.connect().catch((err) => toast.error(err.message));
          }}
        >
          <img src={MetaFox} className="mr-3" alt="metafans logo" />
          <p className="font-bold text-white text-md">Connect Wallet</p>
        </Button>
      );
    }

    return <InstallButton />;
  }

  return (
    <div className="flex justify-center items-center bg-primary rounded-xl py-5 mx-auto min-w-[21rem]">
      <img src={MetaFox} className="mr-3" alt="metafans logo" />
      <p className="font-bold text-white text-md">Your Wallet is Connected!</p>
    </div>
  );
};

export default MetaConnect;
