import React from 'react';
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemPanel,
  AccordionItemButton,
} from 'react-accessible-accordion';
import faqSlug from '../../views/Home.jsx';
import open from '../../../assets/images/open.svg';

function Faqs() {
  const queryParams = new URLSearchParams(window.location.search);
  const faqSlug = queryParams.get('faqSlug');
  console.log(faqSlug);

  return (
    <section className="faqs" id="faq">
      <div className="px-5 md:px-10">
        <div className="text-center">
          <h1 className="text-3xl font-bold lt-white md:text-4xl">
            MetaFans FAQs
          </h1>
        </div>
        <Accordion allowZeroExpanded="true" preExpanded={[faqSlug]}>
          <AccordionItem faqSlug="what" uuid="what">
            <AccordionItemHeading>
              <AccordionItemButton>
                <div className="items-center justify-center px-3 py-1 font-bold text-center text-white rounded-lg lg:hidden faq-number bg-red h-fit w-fit text-md md:text-lg md:px-5 md:py-2">
                  1
                </div>
                <div className="flex items-center justify-between mt-5 xl:mt-0">
                  <div className="flex items-start gap-5 numbered-faq">
                    <div className="hidden lg:flex faq-number bg-red rounded-lg text-white h-[40px] w-[40px] justify-center items-center text-xl font-bold">
                      1
                    </div>
                    <h2 className="text-xl font-bold lt-white md:text-2xl">
                      What is an NFT?
                    </h2>
                  </div>
                  <div className="open-img w-[2.8rem]">
                    <img className="w-full" src={open} alt="Open FAQ" />
                  </div>
                </div>
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel id="what-is-metafans">
              <p className="text-grey">
                An NFT, or non-fungible token, is a unique digital item you can buy, sell, or own. Think of it like a rare
                trading card, but in digital form. Each NFT is one-of-a-kind& has a special code, making it different
                from other digital items. So, even though digital art or music can be copied, the NFT proves who is the
                original owner.
              </p>
            </AccordionItemPanel>
          </AccordionItem>
          <AccordionItem faqSlug="mint" uuid="mint">
            <AccordionItemHeading>
              <AccordionItemButton>
                <div className="items-center justify-center px-3 py-1 font-bold text-center text-white rounded-lg lg:hidden faq-number bg-red h-fit w-fit text-md md:text-lg md:px-5 md:py-2">
                  2
                </div>
                <div className="flex items-center justify-between mt-5 xl:mt-0">
                  <div className="w-[calc(100%-3rem)] flex items-start gap-5 numbered-faq">
                    <span className="hidden lg:flex faq-number bg-red rounded-lg text-white h-[40px] w-[40px] justify-center items-center text-xl font-bold">
                      2
                    </span>
                    <h2 className="text-xl font-bold lt-white md:text-2xl">
                      How to create a wallet and install MetaMask?
                    </h2>
                  </div>
                  <div className="open-img w-[2.8rem]">
                    <img className="w-full" src={open} alt="Open FAQ" />
                  </div>
                </div>
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              <p className="text-grey">
                Visit <a href='https://metamask.io/' target='_blank'>https://metamask.io/</a> Hit &quot;Download&quot; in the menu bar to attach MetaMask to your browser and
                create a wallet with a password you choose. Meta mask will assign your wallet a 12-word phrase to
                keep your wallet secure. Save it and keep it in a safe place for only you to view. This wallet will allow
                you to hold cryptocurrency, such as Ethereum which is used to pay for the real money benefits (RMB)
                offered by the MetaFans sports and poker club.
              </p>
            </AccordionItemPanel>
          </AccordionItem>
          <AccordionItem faqSlug="how" uuid="how">
            <AccordionItemHeading>
              <AccordionItemButton>
                <div className="items-center justify-center px-3 py-1 font-bold text-center text-white rounded-lg lg:hidden faq-number bg-red h-fit w-fit text-md md:text-lg md:px-5 md:py-2">
                  3
                </div>
                <div className="flex items-center justify-between mt-5 xl:mt-0">
                  <div className="w-[calc(100%-3rem)] flex items-start gap-5 numbered-faq">
                    <span className="hidden lg:flex faq-number bg-red rounded-lg text-white h-[40px] w-[40px] justify-center items-center text-xl font-bold">
                      3
                    </span>
                    <h2 className="text-xl font-bold lt-white md:text-2xl">
                      What is a set of MetaFans?
                    </h2>
                  </div>
                  <div className="open-img w-[2.8rem]">
                    <img className="w-full" src={open} alt="Open FAQ" />
                  </div>
                </div>
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              <p className="text-grey">
                To own a complete set a holder needs The Jock, The Cheerleader, The Die Hard, and the Average Joe,
                to get enhanced member benefits!
              </p>
              {/* <ul className="text-grey">
                <li>Background</li>
                <li>Skin</li>
                <li>Tattoo</li>
                <li>Eyes</li>
                <li>Eyelashes</li>
                <li>Eyebrows</li>
                <li>Clothing</li>
                <li>Necklace</li>
                <li>Facial Hair (N/A for Cheerleader)</li>
                <li>Hair</li>
                <li>Mouth</li>
                <li>Jewelry</li>
                <li>Eyewear</li>
                <li>Headwear</li>
                <li>Accessory Left</li>
                <li>Accessory Right</li>
              </ul> */}
            </AccordionItemPanel>
          </AccordionItem>
          <AccordionItem faqSlug="artist" uuid="artist">
            <AccordionItemHeading>
              <AccordionItemButton>
                <div className="items-center justify-center px-3 py-1 font-bold text-center text-white rounded-lg lg:hidden faq-number bg-red h-fit w-fit text-md md:text-lg md:px-5 md:py-2">
                  4
                </div>
                <div className="flex items-center justify-between mt-5 xl:mt-0">
                  <div className="w-[calc(100%-3rem)] flex iitems-start gap-5 numbered-faq">
                    <span className="hidden lg:flex faq-number bg-red rounded-lg text-white h-[40px] w-[40px] justify-center items-center text-xl font-bold">
                      4
                    </span>
                    <h2 className="text-xl font-bold lt-white md:text-2xl">
                      What are the Rarities?
                    </h2>
                  </div>
                  <div className="open-img w-[2.8rem]">
                    <img className="w-full" src={open} alt="Open FAQ" />
                  </div>
                </div>
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel className="text-grey">
              <p className="text-grey">
                Each MetaFan is algorithmically generated by distributing 9988 unique assets amongst 4 avatars with
                varying rarities in 16 categories. Football Jersey, Baseball Jersey, Team hat and Tattoos are 4 category
                examples.
              </p>
            </AccordionItemPanel>
          </AccordionItem>
          <AccordionItem faqSlug="benefits" uuid="benefits">
            <AccordionItemHeading>
              <AccordionItemButton>
                <div className="items-center justify-center px-3 py-1 font-bold text-center text-white rounded-lg lg:hidden faq-number bg-red h-fit w-fit text-md md:text-lg md:px-5 md:py-2">
                  5
                </div>
                <div className="flex items-center justify-between mt-5 xl:mt-0">
                  <div className="w-[calc(100%-3rem)] flex items-start gap-5 numbered-faq">
                    <span className="hidden lg:flex faq-number bg-red rounded-lg text-white h-[40px] w-[40px] justify-center items-center text-xl font-bold">
                      5
                    </span>
                    <h2 className="text-xl font-bold lt-white md:text-2xl">
                      How can I trade for other MetaFans to complete a set?
                    </h2>
                  </div>
                  <div className="open-img w-[2.8rem]">
                    <img className="w-full" src={open} alt="Open FAQ" />
                  </div>
                </div>
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel className="text-grey">
              <p className="text-grey">
                Join the MetaFans discord <a className='underline' href='https://discord.gg/metafans'>here</a> & look for the TRADES
                channel. Post what you are trading& what you’re looking for& wait for a reply. You may also reach out
                to CryptoDaddio who will be more than happy and able to trade with you.
              </p>
              {/* <ul className="text-grey">
                <li>
                  Ticket giveaways for IRL experiences to concerts, sporting
                  events, and more.
                </li>
                <li>
                  Access to additional perks on our sports and poker platform.
                  
                </li>
                <li>
                  Access to an amazing community of like-minded people with
                  daily games and chances to win!
                </li>
              </ul> */}
            </AccordionItemPanel>
          </AccordionItem>
          
          <AccordionItem faqSlug="Football" uuid="Football">
            <AccordionItemHeading>
              <AccordionItemButton>
                <div className="items-center justify-center px-3 py-1 font-bold text-center text-white rounded-lg lg:hidden faq-number bg-red h-fit w-fit text-md md:text-lg md:px-5 md:py-2">
                  6
                </div>
                <div className="flex items-center justify-between mt-5 xl:mt-0">
                  <div className="w-[calc(100%-3rem)] flex items-start gap-5 numbered-faq">
                    <span className="hidden lg:flex faq-number bg-red rounded-lg text-white h-[40px] w-[40px] justify-center items-center text-xl font-bold">
                      6
                    </span>
                    <h2 className="text-xl font-bold lt-white md:text-2xl">
                      What are MetaFans NFL/College Football square pools?
                    </h2>
                  </div>
                  <div className="open-img w-[2.8rem]">
                    <img className="w-full" src={open} alt="Open FAQ" />
                  </div>
                </div>
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel className="text-grey">
              <p className="text-grey">
                A betting pool where entrants make random picks for the score of the game. These entries are known
                as &quot;squares&quot; where players either pick or are assigned a cell in a 10 by 10 grid of 100 squares.
              </p>
            </AccordionItemPanel>
          </AccordionItem>
          <AccordionItem faqSlug="College" uuid="College">
            <AccordionItemHeading>
              <AccordionItemButton>
                <div className="items-center justify-center px-3 py-1 font-bold text-center text-white rounded-lg lg:hidden faq-number bg-red h-fit w-fit text-md md:text-lg md:px-5 md:py-2">
                  7
                </div>
                <div className="flex items-center justify-between mt-5 xl:mt-0">
                  <div className="w-[calc(100%-3rem)] flex items-start gap-5 numbered-faq">
                    <span className="hidden lg:flex faq-number bg-red rounded-lg text-white h-[40px] w-[40px] justify-center items-center text-xl font-bold">
                      7
                    </span>
                    <h2 className="text-xl font-bold lt-white md:text-2xl">
                      How do I pick my MetaFans NFL/College Football squares?
                    </h2>
                  </div>
                  <div className="open-img w-[2.8rem]">
                    <img className="w-full" src={open} alt="Open FAQ" />
                  </div>
                </div>
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel className="text-grey">
              <p className="text-grey">
                Members of MetaFans NFL/College Football Squares pool will click the invite link in <a href='https://discord.gg/metafans' target='_blank' className='underline'>discord</a> & simply login to the website and click the square(s) they want. When signing up on the website use your <a href='https://discord.gg/metafans' target='_blank' className='underline'>discord</a> username and email.
              </p>
            </AccordionItemPanel>
          </AccordionItem>
          <AccordionItem faqSlug="square" uuid="square">
            <AccordionItemHeading>
              <AccordionItemButton>
                <div className="items-center justify-center px-3 py-1 font-bold text-center text-white rounded-lg lg:hidden faq-number bg-red h-fit w-fit text-md md:text-lg md:px-5 md:py-2">
                  8
                </div>
                <div className="flex items-center justify-between mt-5 xl:mt-0">
                  <div className="w-[calc(100%-3rem)] flex items-start gap-5 numbered-faq">
                    <span className="hidden lg:flex faq-number bg-red rounded-lg text-white h-[40px] w-[40px] justify-center items-center text-xl font-bold">
                      8
                    </span>
                    <h2 className="text-xl font-bold lt-white md:text-2xl">
                      How are the “Y”  & “X” axis numbers chosen in an NFL/College Football square pool?
                    </h2>
                  </div>
                  <div className="open-img w-[2.8rem]">
                    <img className="w-full" src={open} alt="Open FAQ" />
                  </div>
                </div>
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel className="text-grey">
              <p className="text-grey">
                After all the squares have a player or the NFL/College game begins, a team is assigned to “X”& “Y”
                and each box on the axis is assigned at random a number 0-9 so that everyone has a fair chance of
                winning.
              </p>
            </AccordionItemPanel>
          </AccordionItem>
        
          
          
          <AccordionItem faqSlug="rmb" uuid="rmb">
            <AccordionItemHeading>
              <AccordionItemButton>
                <div className="items-center justify-center px-3 py-1 font-bold text-center text-white rounded-lg lg:hidden faq-number bg-red h-fit w-fit text-md md:text-lg md:px-5 md:py-2">
                  9
                </div>
                <div className="flex items-center justify-between mt-5 xl:mt-0">
                  <div className="w-[calc(100%-3rem)] flex items-start gap-5 numbered-faq">
                    <span className="hidden lg:flex faq-number bg-red rounded-lg text-white h-[40px] w-[40px] justify-center items-center text-xl font-bold">
                      9
                    </span>
                    <h2 className="text-xl font-bold lt-white md:text-2xl">
                      What is RMB?
                    </h2>
                  </div>
                  <div className="open-img w-[2.8rem]">
                    <img className="w-full" src={open} alt="Open FAQ" />
                  </div>
                </div>
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel className="text-grey">
              <p className="text-grey">
                RMB is an acronym for real money benefits. These are benefits given to MetaFan NFT holders that win contests.
              </p>
            </AccordionItemPanel>
          </AccordionItem>
          <AccordionItem faqSlug="nlh" uuid="nlh">
            <AccordionItemHeading>
              <AccordionItemButton>
                <div className="items-center justify-center px-3 py-1 font-bold text-center text-white rounded-lg lg:hidden faq-number bg-red h-fit w-fit text-md md:text-lg md:px-5 md:py-2">
                  10
                </div>
                <div className="flex items-center justify-between mt-5 xl:mt-0">
                  <div className="w-[calc(100%-3rem)] flex items-start gap-5 numbered-faq">
                    <span className="hidden lg:flex faq-number bg-red rounded-lg text-white h-[40px] w-[40px] justify-center items-center text-xl font-bold">
                      10
                    </span>
                    <h2 className="text-xl font-bold lt-white md:text-2xl">
                      What is NLH?
                    </h2>
                  </div>
                  <div className="open-img w-[2.8rem]">
                    <img className="w-full" src={open} alt="Open FAQ" />
                  </div>
                </div>
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel className="text-grey">
              <p className="text-grey">
                NLH is an acronym for No-Limit hold'em, the style of poker we play in our home games. In NLH, players can wager up to the size of their stack as they’d like at any point in the hand. This is what makes the game so exciting. You can potentially double-up in one hand
              </p>
            </AccordionItemPanel>
          </AccordionItem>
          <AccordionItem faqSlug="pko" uuid="pko">
            <AccordionItemHeading>
              <AccordionItemButton>
                <div className="items-center justify-center px-3 py-1 font-bold text-center text-white rounded-lg lg:hidden faq-number bg-red h-fit w-fit text-md md:text-lg md:px-5 md:py-2">
                  11
                </div>
                <div className="flex items-center justify-between mt-5 xl:mt-0">
                  <div className="w-[calc(100%-3rem)] flex items-start gap-5 numbered-faq">
                    <span className="hidden lg:flex faq-number bg-red rounded-lg text-white h-[40px] w-[40px] justify-center items-center text-xl font-bold">
                      11
                    </span>
                    <h2 className="text-xl font-bold lt-white md:text-2xl">
                      What is PKO?
                    </h2>
                  </div>
                  <div className="open-img w-[2.8rem]">
                    <img className="w-full" src={open} alt="Open FAQ" />
                  </div>
                </div>
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel className="text-grey">
              <p className="text-grey">
                PKO is an acronym for progressive knockout. In this format of poker, every time you knock out a player, you win half their bounty while the remaining half goes onto your own bounty. This means that the more players you knock out, the bigger your bounty will be.
              </p>
            </AccordionItemPanel>
          </AccordionItem>
          <AccordionItem faqSlug="tourneys" uuid="tourneys">
            <AccordionItemHeading>
              <AccordionItemButton>
                <div className="items-center justify-center px-3 py-1 font-bold text-center text-white rounded-lg lg:hidden faq-number bg-red h-fit w-fit text-md md:text-lg md:px-5 md:py-2">
                  12
                </div>
                <div className="flex items-center justify-between mt-5 xl:mt-0">
                  <div className="w-[calc(100%-3rem)] flex items-start gap-5 numbered-faq">
                    <span className="hidden lg:flex faq-number bg-red rounded-lg text-white h-[40px] w-[40px] justify-center items-center text-xl font-bold">
                      12
                    </span>
                    <h2 className="text-xl font-bold lt-white md:text-2xl">
                      What are all MetaFans weekly poker tourneys?
                    </h2>
                  </div>
                  <div className="open-img w-[2.8rem]">
                    <img className="w-full" src={open} alt="Open FAQ" />
                  </div>
                </div>
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel className="text-grey">
              <p className="text-grey">
                All current MetaFans weekly poker tourneys included in RMB are the BetOnDrew $5.50 NLH PKO & the MetaFans-BetOnDrw $8.80 NLH home games.
              </p>
            </AccordionItemPanel>
          </AccordionItem>
          <AccordionItem faqSlug="owning" uuid="owning">
            <AccordionItemHeading>
              <AccordionItemButton>
                <div className="items-center justify-center px-3 py-1 font-bold text-center text-white rounded-lg lg:hidden faq-number bg-red h-fit w-fit text-md md:text-lg md:px-5 md:py-2">
                  13
                </div>
                <div className="flex items-center justify-between mt-5 xl:mt-0">
                  <div className="w-[calc(100%-3rem)] flex items-start gap-5 numbered-faq">
                    <span className="hidden lg:flex faq-number bg-red rounded-lg text-white h-[40px] w-[40px] justify-center items-center text-xl font-bold">
                      13
                    </span>
                    <h2 className="text-xl font-bold lt-white md:text-2xl">
                      Other benefits for owning a MetaFan?
                    </h2>
                  </div>
                  <div className="open-img w-[2.8rem]">
                    <img className="w-full" src={open} alt="Open FAQ" />
                  </div>
                </div>
              </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel className="text-grey">
              <p className="text-grey">
                Holders connect with top handicappers for sports. MetaFans offers IRL meetups, concerts, and game
                tickets as prizes or bonuses to our members. Our <a href='https://discord.gg/metafans' target='_blank' className='underline'>discord</a> is also a place to engage with other sports
                and poker minded individuals to have conversations and root each other’s favorite teams on to victory.
              </p>
            </AccordionItemPanel>
          </AccordionItem>
        </Accordion>
      </div>
    </section>
  );
}

export default Faqs;
