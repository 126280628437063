import React, { useState } from 'react';

const HowToWinCard = ({ title, items, flipItems, titleFont = 'text-4xl', descFont = 'text-md md:text-xl', minHeight }) => {
    const [flipped, setFlipped] = useState(true);

    return (
        <div className={`relative w-full shadow-lg htw-card flip-card ${minHeight ? minHeight : ''}`}>
            <div class="flip-card-inner">
                <div className={`first-view p-4 md:p-10 flip-card-front`}>
                    <h2 className={`${titleFont} mb-2 font-semibold text-center text-white`}>{title}</h2>
                    <ul className="w-full mt-10 space-y-1 text-gray-500 list-inside">
                        {items.map((item, index) => (
                            <li key={index} className={`flex items-center ${descFont}`}>
                                <svg className="w-3.5 h-3.5 mb-auto mt-1 mr-3 text-green-500 dark:text-green-400 flex-shrink-0" ariaHidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                                    <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                                </svg>
                                <div className='htmldiv' dangerouslySetInnerHTML={{ __html: item }}></div>
                            </li>
                        ))}
                    </ul>
                </div>
                <div className={`second-view p-10 flip-card-back`}>
                    <ul class="w-full space-y-1 text-gray-500 mt-10 list-inside">
                        {flipItems.map((item, index) => (
                            <li key={index} className="flex items-center">
                                <svg className="w-3.5 h-3.5 mb-auto mt-1 mr-3 text-green-500 dark:text-green-400 flex-shrink-0" ariaHidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                                    <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                                </svg>
                                {item}
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
        </div>
    );
};

export default HowToWinCard;