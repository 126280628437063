import React, { useState, useEffect } from 'react';
import classnames from 'classnames';
import { IoCloseOutline, IoCheckmark } from 'react-icons/io5';
import { FaDollarSign, FaClock } from 'react-icons/fa';

import { format } from 'date-fns';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import { formatEther } from '@ethersproject/units';
import Button from '../button/Button';
import Input from '../input/Input';

import { metafansContract, datafeedContract } from '../../data/contracts';
import env from '../../data/env';
import errors from '../../data/errors';
import dispatchPixelEvent from '../../fns/common/dispatchPixelEvent';
import {
  useSigner,
  useTotalSupply,
  useWallet,
  useProvider,
} from '../../hooks/useEventStates';
import useMintCountdown from '../../hooks/useMintCountdown';
import { ether, wei } from '../../utils/units';
import { ethers } from 'ethers';

/**
 * @param {Object} p
 * @param {() => void} p.onCancel
 */
export default function OpensaleCard(p) {
  const totalSupply = useTotalSupply();
  const [quantity, setQuantity] = useState('1');
  const [ethPrice, setEthPrice] = useState();
  const [balance, setBalance] = useState();
  const navigate = useNavigate();

  const signer = useSigner();
  const wallet = useWallet();
  const provider = useProvider();
  const mintCountdown = useMintCountdown();

  const quantityNum = Number(quantity);
  const cost = isNaN(quantityNum) ? ether(0.08) : ether(0.08).mul(quantityNum);

  const invalidQuantityNum = isNaN(quantityNum);

  const remainingSupply = env.nftMaxSupply - (totalSupply ?? 0);

  const soldOut = remainingSupply <= 0;

  let errorMessage;

  if (soldOut) {
    errorMessage = errors.soldOut;
  } else if (!quantity || quantityNum < 1) {
    errorMessage = errors.mintOne;
  } else if (invalidQuantityNum) {
    errorMessage = errors.mustBeNumber;
  } else if (quantityNum > remainingSupply) {
    errorMessage = errors.remainingSupply(remainingSupply);
  } else if (quantityNum > env.launchWalletLimit) {
    errorMessage = errors.perWalletTransactionLimit(env.launchWalletLimit);
  } else if (mintCountdown > 0) {
    errorMessage = errors.timeLimit;
  }

  async function getUserBalance() {
    const b = await provider.getBalance(wallet);
    const balanceInEth = formatEther(b);
    setBalance(balanceInEth);
  }

  useEffect(() => {
    getUserBalance();
  }, []);

  const handleSubmit = (ev) => {
    ev.preventDefault();

    if (!metafansContract) {
      toast.error('Not connected to the network. Please refresh.');
      return;
    }

    const frozenQuantity = quantityNum;

    metafansContract
      .connect(signer)
      .launchMint(wei(frozenQuantity), { value: cost })
      .then((tx) => {
        // ensure we move on to the next steps, even if Meta Pixel and Everflow have errors
        try {
          dispatchPixelEvent('MintStart', {
            quantity: frozenQuantity,
          });
        } catch {}

        try {
          const headers = new Headers({
            'content-type': 'application/json',
          });

          headers.set('api-key', env.apiKey || '');

          fetch(`/api/v2/mint-submitted`, {
            method: 'POST',
            body: JSON.stringify({
              source: 'react-app/OpensaleCard',
              amountEth: 0.08 * frozenQuantity,
              chainId: env.chainId,
              transactionHash: tx.hash,
              wallet,
              quantity: frozenQuantity,
              everflowId: localStorage.getItem('everflowid'),
            }),
            headers,
          });
        } catch {}

        const toastId = toast.loading(
          <div className="flex flex-row items-center">
            Mint transaction submitted for {frozenQuantity} NFT
            {frozenQuantity === 1 ? '' : 's'}
          </div>
        );

        return tx
          .wait()
          .then((x) => {
            dispatchPixelEvent('MintPurchase', {
              quantity: frozenQuantity,
            });

            toast.update(toastId, {
              render: (
                <div className="flex flex-row items-center">
                  Mint confirmed for {frozenQuantity} NFT
                  {frozenQuantity === 1 ? '' : 's'}
                </div>
              ),
              type: 'success',
              isLoading: false,
              closeButton: true,
              closeOnClick: true,
            });
            p.onCancel();
            navigate('/thank-you');
          })
          .catch((e) => {
            toast.update(toastId, {
              render: e.message,
              type: 'error',
              isLoading: false,
              closeButton: true,
              closeOnClick: true,
            });
            p.onCancel();
          });
      })
      .catch(() =>
        toast.error('Unexpected error while trying to submit a transaction')
      );
  };

  const fetchEthPrice = async () => {
    const priceFeed = datafeedContract.connect(signer);
    const roundData = await priceFeed.latestRoundData();
    const decimals = await priceFeed.decimals();
    const price = Number(
      (roundData.answer.toString() / Math.pow(10, decimals)).toFixed(2)
    );
    setEthPrice(price);
  };

  useEffect(() => {
    fetchEthPrice();
  }, []);

  return (
    <div
      className={classnames(
        'inline-block align-bottom bg-dark1 rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:w-full sm:max-w-lg z-50',
      )}
    >
      <div className="bg-dark1 px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
        <div className="flex flex-col">
          <div className="flex flex-row items-center justify-between">
            <h3 className="text-3xl leading-6 font-bold text-white">
              Start Minting
            </h3>
            <Button
              className="flex items-center justify-center w-[2.5rem] h-[2.5rem] rounded-full p-1"
              variant="Outline"
              onClick={() => p.onCancel()}
            >
              <IoCloseOutline className="text-2xl" />
            </Button>
          </div>
          <div className="mt-10">
            <form onSubmit={handleSubmit}>
              <div className="flex items-center">
                <div className="flex items-center justify-center w-[2.2rem] h-[2.2rem] rounded-full bg-[#20332e]">
                  <IoCheckmark className="text-xl text-[#30B28C]" />
                </div>
                <div className="flex items-center text-lg font-semibold ml-2">
                  <span className="text-gray-500 mr-1">Wallet Connected:</span>
                  <span className="text-white">
                    {wallet?.slice(0, 6)}...{wallet?.slice(-4)}
                  </span>
                </div>
              </div>
              <div className="text-base text-gray-500 font-semibold mt-8">
                You can mint up to{' '}
                <span className="text-white font-bold">
                  {env.launchWalletLimit} NFTs
                </span>{' '}
                per transaction. Limit of{' '}
                <span className="text-white font-bold">1 mint</span> transaction
                every 15 minutes.
              </div>
              {!soldOut && (
                <>
                  <Input
                    type="tel"
                    className="mt-8"
                    value={quantity}
                    onChange={(ev) => setQuantity(ev.currentTarget.value)}
                  />
                  <span className='text-gray-500 ml-1'>
                    Remaining Supply: {remainingSupply}
                  </span>
                  <div className="flex flex-row bg-[#2A2D36] rounded-xl p-5 mt-8">
                    <div className="flex-1">
                      <h3 className="text-3xl text-white font-semibold">
                        Total
                        <br />
                        Breakdown
                      </h3>
                    </div>
                    <div className="flex-1 text-right">
                      <h3 className="text-3xl text-[#30B38C] font-semibold">
                        {formatEther(cost)} ETH
                      </h3>
                      {ethPrice && (
                        <div className="text-lg text-gray-500 font-semibold">
                          ~ ${(Number(formatEther(cost)) * ethPrice).toFixed(2)}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="flex justify-between items-center mt-8 px-10">
                    <div className="flex items-center">
                      <div className="flex items-center justify-center w-[1.5rem] h-[1.5rem] border-2 border-gray-400 rounded-full mr-2">
                        <FaDollarSign className="text-base text-gray-400" />
                      </div>
                      <div className="text-base text-gray-400 font-semibold">
                        Price
                      </div>
                    </div>
                    {ethPrice && (
                      <div className="text-base text-white font-semibold">
                        1 ETH = ${ethPrice}
                      </div>
                    )}
                  </div>
                  {balance <= formatEther(cost) ? (
                    <div className="mt-8">
                      <Button
                        type="submit"
                        className="w-full font-semibold py-3"
                        variant="GradientOutline"
                        disabled
                      >
                        Insufficient Funds For Mint
                      </Button>
                    </div>
                  ) : (
                    <div className="mt-8">
                      <Button
                        type="submit"
                        className="w-full font-semibold py-3"
                        variant="GradientOutline"
                        disabled={!!errorMessage}
                      >
                        Mint
                      </Button>
                    </div>
                  )}
                </>
              )}
              {soldOut && (
                <div className="mt-8">
                  <Button
                    variant="Outline"
                    className="w-full font-semibold"
                    disabled={true}
                  >
                    SOLD OUT
                  </Button>
                </div>
              )}
              {mintCountdown > 0 && (
                <div className="flex items-center justify-center text-gray-400 font-semibold mt-5">
                  <FaClock className="mr-2" />
                  <div>
                    Time until next mint: {format(mintCountdown, 'm:ss')}
                  </div>
                </div>
              )}
              {errorMessage && (
                <div className="text-center text-red-500 font-semibold mt-5">
                  {errorMessage}
                </div>
              )}
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
