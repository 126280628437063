import React, { Component } from 'react';
import discord from '../../../../assets/images/discord-blue.svg';
import superbowl from '../../../../assets/images/superbowl.jpg';

class NoClock extends Component {
  render() {
    return (
      <section
        className="countdown bg-dkGrey py-10 md:py-16"
        id="something-big"
      >
        <div className="px-5 md:px-10">
          <div className="flex flex-col-reverse lg:flex-row items-center countdown-container">
            <div className="w-full lg:w-5/12 xl:w-1/2">
              <img
                className="secret shadow-gray-50 rounded-lg object-contain h-[60vh] mx-auto mt-10 lg:mt-0"
                src={superbowl}
                alt="metafans superbowl countdown"
              ></img>
            </div>
            <div className="w-full lg:w-7/12 xl:w-1/2 countdown-info md:ml-16 xl:ml-0">
              <h1 className="lt-white font-bold text-3xl md:text-4xl">
              Our Big Game Party is back for its second year, and we look forward to celebrating alongside 200+ of you at the Wrigley Field 1914 Club!
              </h1>
              <p className="text-grey info text-md md:text-lg mt-5">
              Enjoy the game with on-field access, chef-prepared food, a premium open bar, live DJ sets, and more!
              </p>
              {/* Final Phase */}
              {/* <div className="launch-countdown count-two lt-white p-3 bg-dkGrey border-2 border-ltGrey rounded-xl w-full md:w-[85%] mt-10 shadow-gray-900">
                <div className="p-3 pt-4 text-center footer-count-notes final">
                  <p className="text-white font-bold mb-5">
                  Less Than 50 Slots Remain!
                  </p>
                  <a
                    href="https://www.metafans.com/blog/party2023/"
                    target="_blank"
                    className="discord flex justify-center items-center"
                  >
                    <p>RSVP to the Party!</p>
                  </a>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </section>
    );
  }
}
export default NoClock;