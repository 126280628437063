import { Fragment, useCallback, useState } from 'react';

import useEvent from '../../hooks/useEvent';
import useKeyListener from '../../hooks/useKeyListener';
import FanEpackCard from '../cards/FanEpackCard';
import OpensaleCard from '../cards/OpensaleCard';
import ModalSlim from './ModalSlim';

export default function ModalContainer() {
  const [stack, setStack] = useState(
    /** @type {{type: string; args?: any}[]} */ ([])
  );

  const ensureInStack = useCallback(
    (type, args) => {
      if (stack.every((x) => x.type !== type)) {
        const item = { args, type };

        setStack([...stack, item]);
      }
    },
    [stack, setStack]
  );

  const ensureOutStack = useCallback(
    (type) => {
      setStack((x) => x.filter((y) => y.type !== type));
    },
    [setStack]
  );

  const popStack = useCallback(() => {
    setStack((x) => (x.length ? x.slice(0, -1) : x));
  }, [setStack]);

  useEvent('fanEpackModalRequested', () => ensureInStack('fanepack'), [
    ensureInStack,
  ]);
  useEvent('opensaleModalRequested', () => ensureInStack('opensale'), [
    ensureInStack,
  ]);
  useEvent('presaleModalRequested', () => ensureInStack('presale'), [
    ensureInStack,
  ]);

  useKeyListener('Escape', popStack, [stack, setStack]);

  return (
    <>
      {!!stack.length && (
        <div className="modal-slim-overlay" onClick={() => popStack()}></div>
      )}
      {stack.map((x, i) => {
        let el = null;

        switch (x.type) {
          case 'fanepack': {
            el = <FanEpackCard onCancel={() => ensureOutStack(x.type)} />;

            break;
          }
          case 'opensale': {
            el = <OpensaleCard onCancel={() => ensureOutStack(x.type)} />;

            break;
          }
          default: {
            console.error(`Unknown modal: ${x.type}`);

            el = <Fragment />;

            break;
          }
        }

        return (
          <ModalSlim key={x.type} left={`${50 + i}%`} zIndex={10000 + i}>
            {el}
          </ModalSlim>
        );
      })}
    </>
  );
}
