import { BigNumber, constants } from 'ethers';

import { fanEpacksContract, metafansContract } from '../../data/contracts';
import env from '../../data/env';
import dispatchEvent from '../common/dispatchEvent';

/** @type {IMetaFans | undefined} */
let contract;

/** @type {IFanEpacks | undefined} */
let fContract;

const contractWallet = {
  /**
   * @param {string | undefined} wallet
   */
  connect: async function (wallet) {
    if (!(fanEpacksContract && metafansContract)) {
      return;
    }

    if (contract) {
      contract.removeAllListeners();
      contract = undefined;
    }

    if (fContract) {
      fContract.removeAllListeners();
      fContract = undefined;
    }

    if (!wallet) {
      return;
    }

    contract = metafansContract.connect(global.provider);
    fContract = fanEpacksContract.connect(global.provider);

    const syncAllowance = async () => {
      const claimed =
        (await contract?.presaleClaimed(wallet)) ?? BigNumber.from(0);
      const allowance = BigNumber.from(env.presaleWalletLimit).sub(claimed);

      dispatchEvent('allowanceChanged', allowance?.toNumber() ?? 0);
    };

    const syncFanEpackClaims = async () => {
      const res = await fetch(`/api/claims/fanepacks/check/${wallet}`);
      const data = await res.json();

      dispatchEvent('fanEpackClaimsChanged', data);
    };

    const syncLastMint = async () => {
      const lastMint =
        (await contract?.lastMintAt(wallet)) ?? BigNumber.from(0);

      dispatchEvent('lastMintChanged', new Date(lastMint.toNumber() * 1_000));
    };

    const transferFilter = contract.filters.Transfer(
      constants.AddressZero,
      wallet
    );

    contract.on(transferFilter, async () => {
      await syncAllowance();
      await syncFanEpackClaims();
      await syncLastMint();
    });

    const fTransferFilter = fContract.filters.Transfer(
      constants.AddressZero,
      wallet
    );

    fContract.on(fTransferFilter, async () => {
      await syncFanEpackClaims();
    });

    await syncAllowance();
    await syncFanEpackClaims();
    await syncLastMint();
  },
};

export default contractWallet;
