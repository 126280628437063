import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import ScrollToTop from './components/ScrollToTop/ScrollToTop';

import everflow from './fns/common/everflow';
import contractAggregate from './fns/contracts/contractAggregate';
import globalProvider from './fns/contracts/globalProvider';
import contractWalletObserver from './fns/observers/contractWalletObserver';
import globalObserver from './fns/observers/globalObserver';
import signerObserver from './fns/observers/signerObserver';

everflow.processUri();

globalObserver.listen();
contractWalletObserver.listen();
signerObserver.listen();

globalProvider.connect();
contractAggregate.connect();

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Router>
      <ScrollToTop />
      <App />
    </Router>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
