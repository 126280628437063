import { useMemo } from 'react';

/**
 * @param {Object} p
 * @param {import("react").ReactNode} [p.children]
 * @param {import("react").CSSProperties["left"]} [p.left]
 * @param {import("react").CSSProperties["zIndex"]} [p.zIndex]
 */
export default function ModalSlim({ children, left, zIndex }) {
  const initialTop = useMemo(() => {
    const html = document.querySelector('html');

    return html ? html.scrollTop + window.innerHeight / 2 : undefined;
  }, []);

  return (
    <div className="modal-slim" style={{ top: initialTop, left, zIndex }}>
      {children}
    </div>
  );
}
