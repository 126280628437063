const everflow = {
  getUri: function () {
    return localStorage.getItem('everflowid');
  },
  processUri: function () {
    try {
      const url = new URL(window.location.href);

      const everflowid = url.searchParams.get('everflowid');

      if (!everflowid) {
        return;
      }

      url.searchParams.delete('everflowid');

      localStorage.setItem('everflowid', everflowid);

      window.history.replaceState(null, '', url);
    } catch (err) {
      console.error('everflow:processUri', err);
    }
  },
};

export default everflow;
