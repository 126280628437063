import React from 'react';
import Button from '../button/Button';

import dispatchEvent from '../../fns/common/dispatchEvent';

const OpensaleButton = () => {
  return (
    <Button
      variant='GradientOutline'
      className='font-semibold rounded-[2rem] px-10'
      onClick={() => dispatchEvent('opensaleModalRequested')}
    >
      Mint
    </Button>
  );
};

export default OpensaleButton;
