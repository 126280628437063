import React from 'react';
import DocumentMeta from 'react-document-meta';
import '../../styles/app.css';
import '../../styles/metafans.scss';
import Main from '../sections/FunWithDrew/Main';
import bghtw from '../../assets/images/bghtw1.jpg';

function FunWithDrew() {
   
    const meta = {
        title: 'Fun With Drew | MetaFans NFT',
        description:
            'Committed to bringing people together, providing unforgettable IRL experiences. Learn about the MetaFans and our supported charities',
    };
    return (
        <>
            <DocumentMeta {...meta}></DocumentMeta>
            <div className="theme-dk-blue" style={{ backgroundImage: `url(${bghtw})`, backgroundSize: 'cover', backgroundPosition: 'center' }}>
                <Main />
            </div>
            
        </>
    );
}

export default FunWithDrew;
