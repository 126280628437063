import React from 'react';
import Count from '../sections/Home/comps/Count';
import winner1 from '../../assets/images/elimwinner1.jpg';
import winner2 from '../../assets/images/elimwinner2.jpg';
import winner3 from '../../assets/images/elimwinner3.jpg';
import winner4 from '../../assets/images/elimwinner4.jpg';
import ellipse from '../../assets/images/ellipse.svg';
import Slider from 'react-slick';
import { Carousel } from '3d-react-carousal';
function ClubhouseLanding() {
  let slides = [
    <img
      className="w-full max-h-[50vh] px-1 rounded-lg object-scale-down"
      src={winner1}
    />,
    <img
      className="w-full max-h-[50vh] px-1 rounded-lg object-scale-down"
      src={winner2}
    />,
    <img
      className="w-full max-h-[50vh] px-1 rounded-lg object-scale-down"
      src={winner3}
    />,
    <img
      className="w-full max-h-[50vh] px-1 rounded-lg object-scale-down"
      src={winner4}
    />,
  ];
  const mobileSliderSettings = {
    slidesToShow: 1,
    dots: true,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 4000,
    infinite: true,
  };
  return (
    <section className="clubhouse-landing py-20">
      <div className="text-center relative overflow-hidden pb-36 bg-bgDark">
        <div className="absolute z-0 bg-gridBack overflow-visible h-[50vh] bg-repeat bg-bottom -bottom-10 lg:bottom-10 w-full winner-bg-slide" />
        <div className="relative px-5 md:px-10">
          <div className="z-10">
            <h1 className="w-[90%] lg:w-[50%] xl:w-[35%] mx-auto lt-white font-bold text-4xl md:text-5xl">
              Congratulations to our Eliminator Winners!
            </h1>
            <p className="w-[80%] lg:w-[40%] xl:w-[25%] mx-auto text-grey info text-md md:text-lg mt-5">
              After 18 weeks and over 6000 cards eliminated, only 4
              survived! Congrats to @hankfree, @MLS_Bet, @ericizzy007 and
              @WoodyWood on splitting the $25,000 grand prize!
            </p>
          </div>
          <div className="mt-20">
            <div className="hidden md:block relative w-full min-h-[500px] mx-auto z-10 relative">
              <Carousel
                slides={slides}
                autoplay={true}
                interval={4000}
                arrows={false}
                className="delay-1000"
              />
            </div>
            <div className="block md:hidden z-10 relative w-[90%] md:w-[70%] h-full mx-auto">
              <Slider
                className="winners-slider w-full mx-auto"
                {...mobileSliderSettings}
              >
                <img
                  className="w-full h-[40vh] rounded-lg object-scale-down"
                  src={winner1}
                />
                <img
                  className="w-full h-[40vh] rounded-lg object-scale-down"
                  src={winner2}
                />
                <img
                  className="w-full h-[40vh] rounded-lg object-scale-down"
                  src={winner3}
                />
                <img
                  className="w-full h-[40vh] rounded-lg object-scale-down"
                  src={winner4}
                />
              </Slider>
            </div>
            <div className="animate-pulse -mt-16 w-full h-full ellipse-container">
              <img className="ellipse mx-auto" src={ellipse} />
            </div>
          </div>
        </div>
      </div>
      <Count />
    </section>
  );
}

export default ClubhouseLanding;
