import Button from '../../button/Button';

export default function DeskSliderContent({
  leftHeader,
  centerHeader,
  rightHeader,
  leftText,
  slideNumber,
  imgUrl,
  rightText,
  cta,
  ctaLink,
  ctaOnClick,
}) {
  return (
    <div>
      <div className="flex items-start justify-center title-slider">
        <div className="w-1/4 slide-item">
          <div className="overflow-title">
            <h1 className="pt-10 mb-0 text-3xl font-bold leading-tight text-center outside-header text-textGrey">
              {leftHeader}
            </h1>
          </div>
        </div>
        <div className="w-1/2 slide-item slide-active">
          <div className="text-center active-title">
            <h1 className="pt-10 text-5xl font-bold tracking-tight lt-white">
              {centerHeader}
            </h1>
          </div>
        </div>
        <div className="w-1/4 slide-item">
          <div className="overflow-title">
            <h1 className="pt-10 mb-0 text-3xl font-bold leading-tight text-center outside-header text-textGrey">
              <div dangerouslySetInnerHTML={{ __html: rightHeader }}></div>
            </h1>
          </div>
        </div>
      </div>
      <div className="flex items-start justify-center content-slider">
        <div className="w-1/4 slide-item">
          <div className="slide-desc left">
            
            <p className="text-lg font-medium tracking-tight text-textltGrey">
              <div className='leftDesc' dangerouslySetInnerHTML={{ __html: leftText }}></div>
            </p>
          </div>
          <div className="slide-number">
            <div className="flex items-center">
              <h2 className="font-bold tracking-tight text-white current-slide-num text-7xl">
                {slideNumber}
              </h2>
              {/* <h3 className="ml-3 text-3xl font-bold tracking-tight total-slide-num text-textltGrey">
                / 03
              </h3> */}
            </div>
          </div>
        </div>
        <div className="w-1/2 slide-item slide-active" style={{ paddingTop: '2rem' }}>
          <div className="text-center active-image">
            <div className="active-graphic">
              <img src={imgUrl} alt={centerHeader} />
            </div>
          </div>
        </div>
        <div className="w-1/4 slide-item">
          <div className="slide-desc right">
            <p className="text-lg font-medium tracking-tight text-textltGrey">
              {rightText}
            </p>
            {ctaLink && (
              // <a className="track-mint" href={ctaLink}>
              //   {cta}
              // </a>
              <Button
                variant="Outline"
                className="border-[#3772FF] mt-4"
                onClick={() => {
                  window.open(ctaLink);
                }}
              >
                {cta}
              </Button>
            )}
            {ctaOnClick && (
              <Button
                variant="Outline"
                className="border-[#3772FF]"
                onClick={ctaOnClick}
              >
                {cta}
              </Button>
            )}
            <Button
              variant="Outline"
              className="border-[#3772FF] mt-4"
              onClick={() => {
                window.open('https://opensea.io/collection/metafans-collection', '_blank');
              }}
            >
              {'Checkout our Collections'}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}
