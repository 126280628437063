import founderIcon from '../../../assets/images/founder-icon.svg';

export default function TeamMembers({ name, imageUrl, title, linkTree }) {
  return (
    <div className="team-single w-full md:w-1/3">
      <div>
        <img
          src={imageUrl}
          className="headshot w-full h-[372px] object-cover rounded-lg text-center"
          alt={name}
        ></img>
      </div>
      <div className="flex justify-between items-start mb-5">
        <div>
          <p className="lt-white name font-bold text-lg">{name}</p>
          <p className="title text-grey font-semibold tracking-tight text-base">
            {title}
          </p>
        </div>
        <div>
          <a href={linkTree} target="_blank">
            <img src={founderIcon} alt="Linktree Icon"></img>
          </a>
        </div>
      </div>
    </div>
  );
}
