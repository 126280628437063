import React, { Component } from 'react';
import Clock from './Clock.jsx';
import NoClock from './NoClock.jsx';

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      deadline: 'February 13, 2022 19:30:00 GMT-0400',
      // SUPER BOWL COUNTDOWN deadline: 'February 13, 2023 19:30:00 GMT-0400',
    };
  }
  render() {
    // SUPER BOWL COUNTDOWN const firstDeadline = 'February 13, 2023 19:30:00 GMT-0400';
    const firstDeadline = 'February 13, 2022 19:30:00 GMT-0400';
    const firstTime = Date.parse(firstDeadline) - Date.parse(new Date());
    console.log('first', firstTime);

    if (firstTime > 0) {
      return (
        <div className="App">
          <Clock deadline={this.state.deadline} />
        </div>
      );
    } else if (firstTime < 0) {
      return <NoClock />;
    }
  }
}
export default App;
