import React from 'react';

function PrivacyPolicy() {
  return (
    <section className="privacy">
      <div className="container mx-auto">
        <h1 className="lt-white font-bold">Privacy Policy for</h1>
        <h1 className="lt-white font-bold">MetaFans LLC</h1>
        <div className="policy-text">
          <p className="text-grey">
            This Privacy Policy (the “Policy”) applies to your use of
            MetaFans.com (the “Site”), owned and operated by MetaFans Media,
            LLC, a Florida Limited Liability Company with a principal address of
            6560 W. Rogers Circle, Ste. 15, Boca Raton, FL 33487 (“MetaFans,”
            “we,” “our,” or “us”). This Policy applies to all visitors, users,
            and members of the Site.
          </p>

          <p className="text-grey">
            Because your privacy is important to us, we want you to understand
            how we collect, store, use, and disclose your information. Please
            read the Policy carefully, as your use of the Site or our services
            constitutes your agreement with the Policy and you provide us with
            explicit and informed consent to the use and processing of your
            personal information (i.e., information that may identify you) in
            accordance with the Policy. If you do not agree with the Policy, do
            not use the Site or our services.
          </p>

          <p className="text-grey">Information we collect</p>
          <p className="text-grey">
            To provide our services and assure the Site is functioning properly,
            it is necessary for us to collect certain information, including
            personal information, as discussed below.
          </p>

          <p className="text-grey">Information you provide</p>
          <p className="text-grey">
            We may collect information that you provide us, including when you
            use the Site, buy merch, request support, create a member account,
            participate in a promotion, sign up for our newsletter or text
            notifications, or contact us in any way. For example:
          </p>

          <p className="text-grey">
            Member Account. To use certain areas of the Site, we may require you
            to sign up for a member account. If you sign up for a member
            account, we may collect your name, mailing address, email address,
            and telephone number.
          </p>
          <p className="text-grey">
            Purchase Merch. To purchase merch, we may collect your name, mailing
            address, email address, telephone number, and payment and billing
            information.
          </p>
          <p className="text-grey">
            Newsletters and Texts. To receive our newsletter and texts, we will
            collect your email address and mobile telephone number respectively.
          </p>
          <p className="text-grey">
            Inquiries and Support. If you contact us directly, we will collect
            your contact information, which may include your name, mailing
            address, email address, and the message contents.
          </p>
          <p className="text-grey">Information Collected Automatically</p>
          <p className="text-grey">
            We may automatically collect the following information when you use
            the Site: IP address, device hardware and software information,
            browser information, usage or access time, and other analytics
            information, including using cookies and other similar technologies.
          </p>

          <p className="text-grey">Cookies</p>
          <p className="text-grey">
            We may place cookies on your browser to identify you upon returning
            to the Site or when you sign-in to your member account. Our Cookie
            Policy can be found here.
          </p>

          <p className="text-grey">How We Use Collected Information</p>
          <p className="text-grey">
            Services. We may use your information in connection with providing
            and performing our services and for other related business purposes
            necessary in our legitimate interests. For example, we may use the
            information to create member accounts, provide and maintain our
            services, process transactions, provide customer support, notify you
            about changes to our Site or services, respond to inquiries, send
            communications, and prevent fraud.
          </p>
          <p className="text-grey">
            Site. We may use your information in connection with operating,
            maintaining, and improving our Site.
          </p>
          <p className="text-grey">
            Legal. We may use your information to protect our legal rights,
            including for fraud prevention, and to comply with our legal
            obligations.
          </p>
          <p className="text-grey">
            Respond to Inquiries. We may use your information to provide
            customer support and respond to your inquiries.
          </p>
          <p className="text-grey">
            Promotions. We may use your personal information to contact you with
            newsletters, promotions, offers, and other information of interest
            related to our Site or services. You may opt-out of receiving such
            communications from us by following the instructions provided in our
            emails or texts.
          </p>
          <p className="text-grey">
            Confirm Identity. We may use your information to confirm your
            identity.
          </p>
          <p className="text-grey">
            Analytics. We may use de-identified information (i.e., information
            that cannot identify you) to improve our Site and services.
          </p>
          <p className="text-grey">How We Disclose Collected Information</p>
          <p className="text-grey">Personal Information</p>
          <p className="text-grey">
            We do not disclose, sell, rent, or trade personal information with
            non-affiliated parties. We will only disclose your personal
            information as follows:
          </p>

          <p className="text-grey">
            Service Providers and Vendors. We may disclose your personal
            information with service providers and vendors who work on our
            behalf to assist us in providing our services, including credit card
            processors, shipping companies, and email service providers. Such
            entities are contractually obligated to comply with this Policy and
            do not have any right to use your personal information beyond what
            is necessary.
          </p>
          <p className="text-grey">
            Legal. We may disclose your personal information if legally
            required, for example, if we believe that it is necessary to comply
            with a law, regulation, or valid legal procedure, such as a subpoena
            or a court order. In addition, we may disclose your information if
            we are merged with or acquired by another company, however, any such
            company will be obligated to comply with this Policy.
          </p>
          <p className="text-grey">
            Our Protection. We may disclose your information to investigate
            fraudulent activity or breaches of security, or to protect our or
            any third party’s rights.
          </p>
          <p className="text-grey">Non-Personal Information</p>
          <p className="text-grey">
            We may disclose de-identified information (i.e., information that
            cannot identify you) for any lawful purpose.
          </p>

          <p className="text-grey">Data Security</p>
          <p className="text-grey">
            Consistent with industry standards, we take appropriate technical
            and organizational measures to secure your information. We use
            Amazon Web Services to store information; please see
            aws.amazon.com/security for more information. However, no method of
            data transmission or storage can be 100% secure, so we are not able
            to guarantee its security. In addition, you are responsible for
            taking precautions to secure your member account username and
            password.
          </p>

          <p className="text-grey">Data Retention</p>
          <p className="text-grey">
            We will maintain your personal information for no longer than is
            necessary for the purposes for which it was collected, as may be
            permitted by law.
          </p>

          <p className="text-grey">Data Transfers</p>
          <p className="text-grey">
            We are a Florida-based entity whose servers are housed in the United
            States. All information you provide to us, and all information we
            collect, is transferred only within the United States, and is
            processed and stored according to United States law.
          </p>

          <p className="text-grey">Children’s Privacy</p>
          <p className="text-grey">
            The Site was not developed or intended for children under 18 years
            of age. We do not knowingly collect information from children under
            18 years of age. Children under 18 years of age may not use the
            Site. If you access the Site, use our services, or provide
            information to us, you represent that you are at least 18 years of
            age. If you are the parent or guardian of a child under 18 years of
            age and learn that he/she has provided us with personal information,
            you may contact us at privacy@metafans.com, and we will act to
            delete such information quickly.
          </p>

          <p className="text-grey">Do Not Track</p>
          <p className="text-grey">
            We do not currently respond to “Do Not Track” signals or other
            similar opt-out mechanisms, other than those specifically set forth
            in this Policy, including general web browser “Do Not Track”
            settings.
          </p>

          <p className="text-grey">California Residents</p>
          <p className="text-grey">
            This section supplements the Policy and applies only to California
            residents. Pursuant to the California Consumer Privacy Act (“CCPA”),
            California residents have certain rights with respect to their
            personal information.
          </p>

          <p className="text-grey">
            The CCPA requires us to provide the categories of personal
            information (as defined in the CCPA) that we collect, or have
            collected in the last 12 months, they include:
          </p>

          <p className="text-grey">
            Identifiers (including IP address, name, username, email address,
            mailing address, telephone number, and other similar identifiers)
          </p>
          <p className="text-grey">
            The categories of personal information listed in the California
            Customer Records statute (Cal. Civ. Code § 1798.80) (including name,
            address, telephone number, credit card number, debit card number, or
            any other financial information)
          </p>
          <p className="text-grey">
            Commercial information (including services purchased or considered,
            payment history, transaction history information)
          </p>
          <p className="text-grey">
            Inference information (including additional products or services we
            think may be of interest to you)
          </p>
          <p className="text-grey">
            Pursuant to the CCPA, we do not “sell” personal information, nor
            have we sold any personal information in the past 12 months.
            Accordingly, there is no need to opt-out of the sale of your
            personal information. We use and disclose the above categories of
            personal information consistent with the business purposes discussed
            within this Policy.
          </p>

          <p className="text-grey">
            Under the CCPA, as California residents, you may request:
          </p>

          <p className="text-grey">
            The categories of personal information we collect or disclose, or
            have collected or disclosed in the past 12 months, about you
          </p>
          <p className="text-grey">
            The categories of sources of personal information we collect or
            disclose, or have collected or disclosed in the past 12 months,
            about you
          </p>
          <p className="text-grey">
            The business or commercial purpose for which we collect personal
            information
          </p>
          <p className="text-grey">
            The categories of third parties with whom we disclose personal
            information
          </p>
          <p className="text-grey">
            The personal information we have collected about you
          </p>
          <p className="text-grey">
            That we delete personal information that we have collected from you,
            subject to limitations and exemptions under the laws.
          </p>
          <p className="text-grey">
            To opt-out of the sale of personal information
          </p>
          <p className="text-grey">
            We will not discriminate against you for exercising any of the above
            rights.
          </p>

          <p className="text-grey">
            Please note, requests can only be made two times in a 12-month
            period at no charge. We reserve the right to charge a fee or deny
            requests with respect to additional requests during such a 12-month
            period. Any information we provide you will only include the
            information that we have collected over the previous 12 months from
            the date of such request(s), subject to limitations and exemptions
            under the laws.
          </p>

          <p className="text-grey">
            In order to protect your personal information, we will need to
            verify your identity before complying with any request(s).
            Accordingly, you may need to provide us with certain personal
            information, such as your name and email address. To designate an
            authorized agent to make such requests on your behalf, in addition
            to the foregoing, you will need to provide a written authorization
            or power of attorney signed by you. If there is no verifiable
            personal information, we may be unable to complete such request(s).
            We will attempt to respond to all verifiable requests within 45 days
            of receipt of such requests.
          </p>

          <p className="text-grey">
            If you are a California resident and would like exercise any of the
            above rights pursuant to the CCPA, please contact us at MetaFans
            Media, LLC, 6560 W. Rogers Circle, Ste. 15, Boca Raton, FL 33487 or
            via email at privacy@metafans.com.
          </p>

          <p className="text-grey">
            Pursuant to California Civil Code section 1798 (also known as “Shine
            the Light”), California residents with an established business
            relationship with us may request certain information once a year
            about our sharing of personal information with third parties for
            direct marketing purposes. If you are a California resident and
            would like a copy of this information, if any, please submit a
            written request to privacy@volume.com.
          </p>

          <p className="text-grey">For European Union Residents</p>
          <p className="text-grey">
            This section supplements the Policy and applies only to European
            Union residents. Pursuant to the General Date Protection Regulation
            (“GDPR”), residents of the European Union who access the Site from
            the European Union have certain rights with respect to their
            “personal data,” as such term is defined in the GDPR.
          </p>

          <p className="text-grey">
            Under the GDPR, European Union residents may have the following
            rights:
          </p>

          <p className="text-grey">
            The right to obtain access to your personal data
          </p>
          <p className="text-grey">
            The right to update or erase inaccurate or incomplete data
          </p>
          <p className="text-grey">
            The right to erase your personal data, subject to limitations under
            the law
          </p>
          <p className="text-grey">
            The right to restrict the processing of personal data
          </p>
          <p className="text-grey">
            The right to transfer or copy personal data
          </p>
          <p className="text-grey">
            The right to withdraw your consent to the processing of personal
            data for certain purposes
          </p>
          <p className="text-grey">
            If you are a European Union resident and would like to exercise your
            rights pursuant to the GDPR, please send us a written request by
            contacting us at MetaFans Media, LLC, 6560 W. Rogers Circle, Ste.
            15, Boca Raton, FL 33487 or by emailing us at privacy@metafans.com.
            We may ask that you verify your identity prior to responding to any
            of the above requests.
          </p>

          <p className="text-grey">Changes to the Policy</p>
          <p className="text-grey">
            We may change this Policy from time to time. If we make any changes
            to this Policy, we will change the “Last Updated” date at the top of
            this webpage. We encourage you to visit this webpage from time to
            time. We may provide additional notice of such changes via email,
            SMS notifications, etc. Your continued use of our Site signifies
            your acceptance of any changes to this Policy. If you do not agree
            with such changes, do not use the Site.
          </p>

          <p className="text-grey">Contact Information</p>
          <p className="text-grey">
            We welcome your comments or questions regarding this Policy. Please
            email us at privacy@metafans.com or contact us at MetaFans Media,
            LLC, 6560 W. Rogers Circle, Ste. 15, Boca Raton, FL 33487.
          </p>
        </div>
      </div>
    </section>
  );
}

export default PrivacyPolicy;
