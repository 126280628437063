import dispatchEvent from '../common/dispatchEvent';
import dispatchPixelEvent from '../common/dispatchPixelEvent';
import walletUtils from '../common/walletUtils';

/**
 * @param {string | undefined} walletish
 */
const dispatchWalletChanged = async function (walletish) {
  const canon = await walletUtils.canon(walletish);

  dispatchEvent('walletChanged', canon);
  dispatchPixelEvent('ConnectWallet');
};

const injected = {
  connect: async function () {
    if (!window.ethereum) {
      return;
    }

    if (global.wallet) {
      return;
    }

    window.ethereum.on('accountsChanged', (accounts) =>
      dispatchWalletChanged(accounts[0])
    );

    window.ethereum.on('chainChanged', (chainId) =>
      dispatchEvent('chainChanged', Number(chainId))
    );

    const [accounts] = await window.ethereum.request({
      method: 'eth_requestAccounts',
      params: [
        {
          eth_accounts: {},
        },
      ],
    });

    const account = accounts || null;

    const chainId = await window.ethereum.request({ method: 'eth_chainId' });

    dispatchEvent('chainChanged', Number(chainId));

    dispatchWalletChanged(account);
  },
};

export default injected;
