/**
 * @template {keyof CustomEventMap} T
 * @param {T} type
 * @param {CustomEventMap[T]} [args]
 */
export default function dispatchEvent(type, args) {
  const event = new CustomEvent(type, { detail: args });

  return window.dispatchEvent(event);
}
