import React from 'react';

import team from '../../../assets/images/meet-the-team.jpg';
import discord from '../../../assets/images/aboutpg_discord.svg';
import location from '../../../assets/images/aboutpg_location.svg';
import heart from '../../../assets/images/aboutpg_heart.svg';
import network from '../../../assets/images/20181844_6238850.svg'

const AboutUs = () => {
  return (
    <section className="about-metafans">
      <div className="px-6 md:px-10">
        <div className="flex flex-col items-center md:flex-row">
          <div className="w-full mr-3 md:px-10 md:w-6/12">
            {/* <h1 className="mb-5 text-4xl font-bold tracking-tight lt-white lg:text-5xl">
              Meet the People behind the MetaFans Project
            </h1>
            <h2 className="mb-4 text-xl font-bold tracking-tight text-grey lg:text-2xl">
              Learn about our team.
            </h2> */}
            <p className='mt-4 mb-4 text-md md:text-lg text-white/80'>Join our <a href='https://discord.gg/metafans' target='_blank' className='underline'>discord</a> Community of Like-minded Individuals who enjoy watching sports, sports betting, sports talk, sports strategy and winning! If you are a great Poker Player or want to learn to be a Card Shark, then you will want to check out our Poker Club as well. All poker Home Games hosted by BetOnDrew on Twitch.</p>
            <p className='mt-4 mb-4 text-md md:text-lg text-white/80'>Take advantage of the MetaFans Real Money Benefits (referred to as RMB) where we pay entry fees & buy-ins for all our sports contests & poker Home Games. Plus, attend sports, concerts & special group events for live sports, live poker tournaments & Special MetaFans events throughout the year. </p>
            <p className='mt-4 mb-4 text-md md:text-lg text-white/80'>Our over 1,400 MetaFans Non-fungible Token (referred to as NFT) Holders and Alumni Members want to share the Benefits of our Sports & Poker Club with You! </p>
          </div>
          <div className="w-full md:px-10 md:w-6/12">
            <img
              className="mx-auto"
              src={network}
              alt="meet the metafans team"
            ></img>
          </div>
        </div>
        {/* <div className="flex flex-col-reverse md:flex-col">
          <div className="flex flex-col items-center justify-center gap-5 py-8 font-bold text-center md:flex-row md:text-left about-stats md:py-16">
            <div className="flex flex-col items-center justify-center w-full gap-2 md:flex-row md:w-1/3 md:justify-start stat-item">
              <div>
                <img
                  className="w-[3rem] h-[3rem]"
                  src={discord}
                  alt="discord users"
                />
              </div>
              <div>
                <p className="text-base text-grey">Discord Users</p>
                <h2 className="text-4xl font-bold tracking-tight lt-white">
                  11.7k
                </h2>
              </div>
            </div>
            <div className="flex flex-col items-center justify-center w-full gap-2 text-center md:flex-row md:text-left md:w-1/3 md:justify-start stat-item">
              <div>
                <img
                  className="w-[3rem] h-[3rem]"
                  src={location}
                  alt="event numbers"
                />
              </div>
              <div>
                <p className="text-base text-grey">Number Of Events</p>
                <h2 className="text-4xl font-bold tracking-tight lt-white">
                  120+
                </h2>
              </div>
            </div>
            <div className="flex flex-col items-center justify-center w-full gap-2 text-center md:flex-row md:text-left md:w-1/3 md:justify-start stat-item">
              <div>
                <img
                  className="w-[3rem] h-[3rem]"
                  src={heart}
                  alt="giving back"
                />
              </div>
              <div>
                <p className="text-base text-grey">
                  Prize Money Given to Holders
                </p>
                <h2 className="text-4xl font-bold tracking-tight lt-white">
                  $75k+
                </h2>
              </div>
            </div>
          </div>
          <div className="about-text">
            <p className="font-medium text-grey">
              Created with the love of sports at its core, MetaFans is the first
              NFT project of its kind, connecting sports fans with exclusive
              experiences. With a commitment to bringing people together, our
              mission is to provide unforgettable IRL experiences and give back
              to the community through our charity partnerships.
            </p>
          </div>
        </div> */}
      </div>
    </section>
  );
};

export default AboutUs;
