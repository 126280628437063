import React from 'react';
import Button from '../button/Button';

import metamaskSvg from '../../assets/images/metamask.svg';

const InstallButton = () => {
  return (
    <Button
      isLink
      variant="Outline"
      href="https://metamask.io"
      target="_blank"
      rel="noopener noreferrer"
      className="flex items-center font-semibold rounded-[2rem] px-10 text-xs lg:text-sm"
    >
      <img className="w-[1.5rem] mr-5" src={metamaskSvg} alt="metamask" />
      Install
    </Button>
  );
};

export default InstallButton;
