import React from 'react';
import DocumentMeta from 'react-document-meta';
import '../../styles/app.css';
import '../../styles/metafans.scss';
import HowToWinCard from '../cards/HowToWinCard.jsx';
import bghtw from '../../assets/images/bghtw1.jpg';
import BgBottom from '../../assets/images/poker_table.jpg';
import Main from '../sections/FunWithDrew/Main.jsx';

function ComingSoon() {
    const cardData = [
        {
            title: 'Poker Club',
            items: ['$5.50 Buy-in for the Weekly BetonDrew NLH PKO Home Game', '$16.50 Buy-in for the New Sunday MetaFans Open NLH PKO Home Game', 'See MetaFans Levels for How to Qualify <a href="/new-member-benefits">here</a>', 'Holders must have an ACR account to participate', 'Download <a href="https://www.betondrew.com/" target="_blank">here</a> use referral code BETONDREW'],
            flipItems: ['All participants must create an ACR Poker account.', 'Anyone who enters a league they are not eligible for will forfeit prizes for all leagues that week.', 'Home Game winner will receive a MetaFan NFT','All prizes for Home Games TBD by management'],
        },
        // {
        //     title: 'MetaFans NFL',
        //     items: ['Weekly Squares for Prime-Time Games (TH, SUN, MON)', 'See MetaFans Levels for Qualification Status', 'Holders must join discord <a href="https://discord.gg/metafans">here</a>  &amp; verify Wallet holdings'],
        //     flipItems: ['All participants must create a DraftKings account.', 'Anyone who enters a league they are not eligible for will forfeit prizes for all leagues that week.', 'All prizes for leagues TBD by management'],
        // },
        // {
        //     title: 'MetaFans College Football',
        //     items: ['Weekly Squares for a Saturday regular season game.', 'See MetaFans Levels for Qualification Status', 'Holders must join discord <a href="https://discord.gg/metafans">here</a>  &amp; verify Wallet holdings'],
        //     flipItems: ['All participants must create a DraftKings account.', 'Anyone who enters a league they are not eligible for will forfeit prizes for all leagues that week.', 'All prizes for leagues TBD by management'],
        // },
        // {
        //     title: 'MetaFans PGA GOLF',
        //     items: ['Weekly Squares for PGA golf tournaments', 'See MetaFans Levels for Qualification Status', 'Holders must join discord <a href="https://discord.gg/metafans">here</a>  &amp; verify Wallet holdings'],
        //     flipItems: ['All participants must create a DraftKings account.', 'Anyone who enters a league they are not eligible for will forfeit prizes for all leagues that week.', 'All prizes for leagues TBD by management'],
        // },
        // {
        //     title: 'MetaFans NBA Playoff Bracket Pool',
        //     items: ['COMING SOON!', 'See MetaFans Levels for Qualification Status', 'Holders must join discord <a href="https://discord.gg/metafans">here</a>  &amp; verify Wallet holdings'],
        //     flipItems: ['All participants must create a DraftKings account.', 'Anyone who enters a league they are not eligible for will forfeit prizes for all leagues that week.', 'All prizes for leagues TBD by management'],
        // },
        // {
        //     title: 'MetaFans March Madness Squares',
        //     items: ['COMING SOON!', 'See MetaFans Levels for Qualification Status', 'Holders must join discord <a href="https://discord.gg/metafans">here</a>  &amp; verify Wallet holdings'],
        //     flipItems: ['All participants must create a DraftKings account.', 'Anyone who enters a league they are not eligible for will forfeit prizes for all leagues that week.', 'All prizes for leagues TBD by management'],
        // },
    ];
    const meta = {
        title: 'Poker Club | MetaFans NFT',
        description:
            'Committed to bringing people together, providing unforgettable IRL experiences. Learn about the MetaFans and our supported charities',
    };
    return (
        <>
            <DocumentMeta {...meta}></DocumentMeta>
            {/* <div class="min-h-screen bg-gray-900 flex flex-col items-center justify-center">
                <h1 className="mb-8 text-5xl font-bold text-white animate-pulse">
                    Coming Soon
                </h1>
                <p className="mb-8 text-lg text-white">
                    We're working hard to bring you something amazing. Stay tuned!
                </p>
            </div> */}
            <div className="flex items-center justify-center my-16 bg-gray-900 mx-30" style={{ backgroundImage: `url(${bghtw})`, backgroundSize: 'cover', backgroundPosition: 'center' }}>
                <div className="grid w-full grid-cols-1 gap-4 md:my-10 md:grid-cols-2">
                    {cardData.map((card, index) => (
                        <div className="relative w-full htw-card1 flip-card">
                            <div class="flip-card-inner">
                                <div className={`first-view p-4 md:p-10 flip-card-front`}>
                                    <h2 class="mb-2 text-3xl md:text-5xl font-bold text-left text-white">{card.title}</h2>
                                    <ul class="w-full space-y-1 text-white mt-10 list-inside">
                                        {card.items.map((item, index) => (
                                            <li key={index} className="flex items-center text-md md:text-lg">
                                                <svg className="w-3.5 h-3.5 mb-auto mt-1 mr-3 text-green-500 dark:text-green-400 flex-shrink-0" ariaHidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                                                    <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
                                                </svg>
                                                <div className='htmldiv' dangerouslySetInnerHTML={{ __html: item }}></div>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    ))}
                    <div className="relative w-full htw-card1 flip-card" >
                        <div class="flip-card-inner" >
                            <div className={`first-view p-4 md:p-10 flip-card-front`}>
                                <img src={BgBottom} className="relative w-full h-[25rem] rounded-3xl  bg-center bg-no-repeat bg-cover" alt="bg-bottom" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="theme-dk-blue" style={{ backgroundImage: `url(${bghtw})`, backgroundSize: 'cover', backgroundPosition: 'center' }}>
                <Main />
            </div>
            
            {/* <img src={BgBottom} className="w-full h-auto bg-center bg-no-repeat bg-contain md:p-20" alt="bg-bottom" /> */}
        </>
    );
}

export default ComingSoon;
