import React, { useRef } from 'react';
import classnames from 'classnames';

const Input = ({
  label,
  prefix,
  error,
  name,
  className,
  required = false,
  textarea = false,
  ...inputProps
}) => {
  const inputRef = useRef(null);
  const elementId = name ? `input-${name}` : '';

  return (
    <div className={classnames('text-left', className)}>
      {label && (
        <label className="block text-gray-500 mb-2" htmlFor={elementId}>
          {label}
        </label>
      )}
      <div
        className="flex flex-row items-center border-2 border-brand-gray rounded-xl cursor-text px-5 py-3"
        onClick={() => {
          inputRef?.current.focus();
        }}
      >
        {!!prefix && !textarea && <div className="text-gray-500">{prefix}</div>}
        {textarea ? (
          <textarea
            className="w-full bg-transparent appearance-none outline-none text-gray-300 border-none"
            name={name}
            id={elementId}
            ref={inputRef}
            {...inputProps}
          />
        ) : (
          <input
            className="w-full bg-transparent appearance-none outline-none text-gray-300 border-none"
            name={name}
            id={elementId}
            ref={inputRef}
            {...inputProps}
          />
        )}
      </div>
      {!!error && <div className="text-xs text-red-400 mt-1">{error}</div>}
    </div>
  );
};

export default Input;
