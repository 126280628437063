import React from 'react';
import { useChain, useWallet } from '../../../hooks/useEventStates';
import Slider from 'react-slick';
import slide5 from '../../../assets/images/drew.png'
import video from '../../../assets/images/video.mp4'
import video1 from '../../../assets/images/video1.mp4'
import video2 from '../../../assets/images/video2.mp4'
import video3 from '../../../assets/images/video3.mp4'
import {
  FaYoutube,
  FaTwitter,
  FaInstagram,
  FaTiktok,
  FaTwitch,
} from 'react-icons/fa';
import owners from '../../../assets/images/poker.gif';
import DeskSliderContent from '../Home/DeskSliderContent.jsx';
import MobileSliderContent from '../Home/MobileSliderContent.jsx';


const Main = () => {
  const account = useWallet();
  const chainId = useChain();

  // const sliderSettings = {
  //   dots: true,
  //   arrows: false,
  //   slidesToShow: 1,
  //   slidesToScroll: 1,
  //   autoplay: true,
  //   autoplaySpeed: 5000,
  //   infinite: true,
  // };

  const sliderSettings = {
    dots: true,
    arrows: true,
    infinite: false,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  const mobileSliderSettings = {
    dots: true,
    arrows: false,
    infinite: false,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <section className="pb:2 md:pb-16 home-vip">
      <div className="px-5 py-10 md:px-10 lg:py-auto">
        <div className="flex flex-col items-center xl:flex-row">
          <div className="w-full pr-20 mr-0 xl:w-1/2 xl:mr-5">
            <h1 className="mb-10 text-4xl font-bold tracking-tight lt-white md:text-5xl">
              Learn poker skills, strategies & win with BetOnDrew
            </h1>
            <h2 className="mb-5 font-normal tracking-tight text-md text-grey md:text-xl">
              I am BetOnDrew, a professional poker player, Twitch streamer & host
              of the weekly BetOnDrew $5.50 NLH PKO & the MetaFans BetOnDrew
              $16.50 NLH PKO Home Game for real money benefits and leaderboard points.
              Compete against me & other MetaFans holders every week. Join my socials
              & download ACR Poker <a className="text-white underline " href='https://www.betondrew.com/' target='_balnk'>here</a>. Use Referral code BETONDREW during signup.
             
            </h2>
            <div className="order-1 col-span-1 mt-4 lg:mt-10 lg:order-2">
              <div className="flex flex-col text-white lg:flex-row">
                <div className="flex flex-row items-center justify-start order-2 gap-5 mt-5 mb-5 mr-0 footer-icons lg:order-1 lg:mt-0 lg:mr-3 lg:mb-0">
                  <div>
                    <a
                      href="https://youtube.com/betondrewpoker"
                      target="_blank"
                      className="discord"
                    >
                      <FaYoutube className="w-6 h-6" />
                    </a>
                  </div>
                  <div>
                    <a
                      href="https://twitter.com/betondrew"
                      target="_blank"
                      className="twitter"
                    >
                      <FaTwitter className="w-6 h-6" />
                    </a>
                  </div>
                  <div>
                    <a
                      href="https://instagram.com/betondrew"
                      target="_blank"
                      className="insta"
                    >
                      <FaInstagram className="w-6 h-6" />
                    </a>
                  </div>
                  <div>
                    <a
                      href="https://twitch.tv/betondrew"
                      target="_blank"
                      className="twitch"
                    >
                      <FaTwitch className="w-6 h-6" />
                    </a>
                  </div>
                  <div>
                    <a
                      href="https://tiktok.com/@betondrew"
                      target="_blank"
                      className="tiktok"
                    >
                      <FaTiktok className="w-6 h-6" />
                    </a>
                  </div>
                </div>
              </div>
            </div>

          </div>
          <div className="w-full mt-8 xl:w-1/2 xl:mt-0 vip-slider">
            <Slider {...sliderSettings}>
              {/* <img className="h-[50vh] lg:h-[65vh] rounded-3xl object-cover" src={slide1} alt="metafans photos"></img>
              <img className="h-[50vh] lg:h-[65vh] rounded-3xl object-cover" src={slide2} alt="irl metafans photos"></img>
              <img className="h-[50vh] lg:h-[65vh] rounded-3xl object-cover" src={slide3} alt="irl metafans photos"></img>
              <img className="h-[50vh] lg:h-[65vh] rounded-3xl object-cover" src={slide4} alt="irl metafans photos"></img> */}
              <img className="h-[50vh] lg:h-[65vh] rounded-3xl object-cover" src={slide5} alt="irl metafans photos"></img>
            </Slider>
          </div>
        </div>
        <section className="genesis-slider" id="collection-slide">
          <div className="px-2 md:px-10">
            <Slider className="desktop-slider" {...sliderSettings}>
              <DeskSliderContent
                id="owners"
                leftHeader=""
                centerHeader=""
                rightHeader=""
                leftText={"Mint a MetaFans NFT and get entry into the BetOnDrew $5.50 NLH PKO & the MetaFans BetOnDrew $16.50 NLH PKO Tourneys every week all year!"}
                slideNumber=""
                imgUrl={owners}
                rightText="Explore all of the MetaFans collections! Learn about the rarest and most valuable NFTs and the different traits available within each collection."
                cta="Learn More"
                ctaLink="/nft-utilities"
              ></DeskSliderContent>
            </Slider>
            <Slider className="py-4 md:py-10 mobile-slider" {...mobileSliderSettings}>
              <MobileSliderContent
                id="owners"
                header=""
                bodyText="Mint a MetaFans NFT and get entry into the BetOnDrew $5.50 NLH PKO & the MetaFans BetOnDrew $16.50 NLH PKO Tourneys every week all year!"
                slideNumber=""
                imageUrl={owners}
                ctaLink="/nft-utilities"
                cta="Learn More"
              ></MobileSliderContent>
            </Slider>
          </div>
        </section>
        {/* <div className="flex flex-col items-center justify-center mt-8 lg:mt-20">
          <h1 className="mb-10 text-4xl font-bold tracking-tight lt-white md:text-5xl">MetaFans holders & subscribers will enjoy competing & cheering each other on in BetOnDrew's Twitch channel. Check out BetOnDrew's stream clips below!</h1>
        </div>  */}
        {/* <video className='mt-10 ml-auto mr-auto' autoplay muted loop>
          <source src={video} type="video/mp4"/>
        </video> */}
        <iframe className='mt-10'
          src="https://player.twitch.tv/?channel=betondrew&parent=www.metafans.com&muted=true"
          height="720"
          width="100%"
          allowfullscreen />
        {/* <video className="w-full h-full mt-3 rounded-lg lg:mt-8" controls muted>
          <source src={video} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
        <video className="w-full h-full mt-8 rounded-lg lg:mt-20" controls muted>
          <source src={video1} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
        <video className="w-full h-full mt-8 rounded-lg lg:mt-20" controls muted>
          <source src={video2} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
        <video className="w-full h-full mt-8 rounded-lg lg:mt-20" controls muted>
          <source src={video3} type="video/mp4" />
          Your browser does not support the video tag.
        </video> */}
      </div>
    </section>
  );
};

export default Main;
