import React from 'react';

import placeholder from '../../../assets/images/placeholder.png';
import bigOne from '../../../assets/images/big1.svg';
import bigTwo from '../../../assets/images/big2.svg';
import bigThree from '../../../assets/images/big3.svg';
//import vip from '../../../assets/images/live-vip.jpg';
import vip from '../../../assets/images/poker/home-footer.png';


const Mint = () => {
  return (
    <section className="pt-16 mint">
      <div className="px-5 md:px-10">
        <img
          className="justify-center mx-auto rounded-lg banner"
          src={vip}
          alt="mint a metafans"
        ></img>
        <h1 className="hidden mt-10 mb-16 text-3xl font-bold text-center lt-white lg:text-4xl">
          Mint a MetaFan NFT
        </h1>
        <div className="flex flex-col hidden mt-5 lg:flex-row mint-list">
          <div className="flex items-center justify-center p-5">
            <div>
              <img
                className="w-[3rem] lg:w-[4rem] xl:w-[4rem]"
                src={bigOne}
                alt="steps to minting a metafan"
              ></img>
            </div>
            <p className="ml-5 -mt-5 text-grey text-md md:text-lg">
              <span className="font-bold">
                Join the ultimate sports and poker club!
              </span>{' '}
              {/* MetaFan holders gain access to weekly sports contests to have a chance to win significant ETH payouts! <a className="underline" href="https://ticket.metafans.com/" target="_blank">discount tickets</a>. */}
            </p>
          </div>
          <div className="flex items-center justify-center p-5 border-y-1 lg:my-0 lg:border-x-1 lg:border-y-0 border-textGrey">
            <div className="py-5 lg:py-0">
              <img
                className="w-[3rem] lg:w-[4rem] xl:w-[4rem]"
                src={bigTwo}
                alt="steps to minting a metafan"
              ></img>
            </div>
            <p className="py-5 ml-5 -mt-5 text-grey text-md md:text-lg lg:py-0">
              <span className="font-bold">
                Experience the holder benefits at sporting events and concerts!
              </span>{' '}
              {/* We provide chances to win tickets to IRL events. Enjoy your
              favorite sporting events from the best seats in the house! */}
            </p>
          </div>
          <div className="flex items-center justify-center p-5 mt-5 lg:mt-0">
            <div>
              <img
                className="w-[3rem] lg:w-[4rem] xl:w-[4rem]"
                src={bigThree}
                alt="steps to minting a metafan"
              ></img>
            </div>
            <p className="ml-5 -mt-5 text-grey text-md md:text-lg">
              <span className="font-bold">Additional Perks at Every Turn!</span>
             
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Mint;
