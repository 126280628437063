import React from 'react';
import { Link } from 'react-router-dom';
import DocumentMeta from 'react-document-meta';

function MintPage() {
  const meta = {
    title: 'MetaFans Ticket Portal',
    description:
      'For MetaFans NFT holders only. Get the best ticket prices to sporting events, concerts, and more!',
  };
  return (
    <>
      <DocumentMeta {...meta}></DocumentMeta>
      <section className="mint-landing">
        <div className="container mx-auto text-center">
          <h1 className="lt-white font-bold">For MetaFans NFT Holders ONLY!</h1>
          <h1 className="lt-white font-bold">
            Get the best prices on tickets to your favorite events!
          </h1>
          <p className="text-grey mb-5">
            We have a deep discount for all your favorite sporting events,
            concerts, and more. Connect your wallet below to take advantage of
            this awesome holder-only benefit!
          </p>
          <div className="mint-ctas flex flex-col sm:flex-row justify-center items-center">
            <div className="connect sm:mr-5">
              <Link className="wallet" to="/">
                Connect Wallet
              </Link>
            </div>
          </div>
          <p className="text-grey mt-5">
            Don't have a MetaFans NFT? Mint one today! And start taking
            advantage of this, plus many more benefits!
          </p>
          <div className="mint-ctas flex flex-col sm:flex-row justify-center items-center">
            <div className="learn">
              <Link className="track-mint" to="/nft-utilities">
                Mint Now
              </Link>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default MintPage;
