const globalObserver = {
  listen: function () {
    window.addEventListener(
      'allowanceChanged',
      (ev) => (global.allowance = ev.detail)
    );
    window.addEventListener(
      'chainChanged',
      (ev) => (global.chainId = ev.detail)
    );
    window.addEventListener(
      'fanEpackClaimsChanged',
      (ev) => (global.fanEpackClaims = ev.detail)
    );
    window.addEventListener(
      'lastMintChanged',
      (ev) => (global.lastMint = ev.detail)
    );
    window.addEventListener(
      'providerChanged',
      (ev) => (global.provider = ev.detail)
    );
    window.addEventListener(
      'signerChanged',
      (ev) => (global.signer = ev.detail)
    );
    window.addEventListener(
      'totalSupplyChanged',
      (ev) => (global.totalSupply = ev.detail)
    );
    window.addEventListener(
      'walletChanged',
      (ev) => (global.wallet = ev.detail)
    );
  },
};

export default globalObserver;
