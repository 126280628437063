import Button from '../../button/Button';

export default function MobileSliderContent({
  header,
  bodyText,
  slideNumber,
  imageUrl,
  ctaLink,
  cta,
  ctaOnClick,
}) {
  return (
    <div>
      <h1 className="text-3xl font-bold lt-white tracking-right">{header}</h1>
      <p className="text-lg font-medium tracking-tight text-textltGrey">
        {bodyText}
      </p>
      <div className="slide-number">
        <div className="flex flex-col sm:flex-row sm:items-center sm:justify-between">
          <div className="flex items-center mt-5">
            <h2 className="text-5xl font-bold tracking-tight text-white current-slide-num">
              {slideNumber}
            </h2>
            {/* <h3 className="ml-3 text-xl font-bold tracking-tight total-slide-num text-textltGrey">
              / 03
            </h3> */}
          </div>
          <div className="mt-5 mobile-slide-cta sm:mt-4">
            {ctaLink && (
              <a className="track-mint" href={ctaLink}>
                {cta}
              </a>
            )}
            {ctaOnClick && (
              <Button
                variant="Outline"
                className="border-[#3772FF]"
                onClick={ctaOnClick}
              >
                {cta}
              </Button>
            )}
          </div>
        </div>
      </div>
      <div className="slide-image">
        <img src={imageUrl} alt={header} />
      </div>
    </div>
  );
}
