import { Interface } from 'ethers/lib/utils';

import { Contract } from '@ethersproject/contracts';

import env from './env';

export const fanEpacksContract =
  env.fanEpacksAddress &&
  /** @type {IFanEpacks} */ (
    new Contract(
      env.fanEpacksAddress,
      new Interface([
        'event Transfer(address indexed from, address indexed to, uint256 indexed tokenId)',
        'function claim(uint256 quantity, uint256 earned, uint8 v, bytes32 r, bytes32 s) external',
      ])
    )
  );

export const metafansContract =
  env.metafansAddress &&
  /** @type {IMetaFans} */ (
    new Contract(
      env.metafansAddress,
      new Interface([
        'event Transfer(address indexed from, address indexed to, uint256 indexed tokenId)',
        'function balanceOf(address owner) external view returns (uint256 balance)',
        'function totalSupply() external view returns (uint256)',
        'function lastMintAt(address wallet) external view returns (uint256)',
        'function presaleClaimed(address wallet) external view returns (uint256)',
        'function launchMint(uint256 quantity) external payable',
        'function presaleMint(uint256 quantity, uint256 deadline, uint8 v, bytes32 r, bytes32 s) external payable',
      ])
    )
  );

export const datafeedContract =
  env.ethDatafeedAddress &&
  new Contract(
    env.ethDatafeedAddress,
    new Interface([
      {
        inputs: [],
        name: 'decimals',
        outputs: [{ internalType: 'uint8', name: '', type: 'uint8' }],
        stateMutability: 'view',
        type: 'function',
      },
      {
        inputs: [],
        name: 'description',
        outputs: [{ internalType: 'string', name: '', type: 'string' }],
        stateMutability: 'view',
        type: 'function',
      },
      {
        inputs: [{ internalType: 'uint80', name: '_roundId', type: 'uint80' }],
        name: 'getRoundData',
        outputs: [
          { internalType: 'uint80', name: 'roundId', type: 'uint80' },
          { internalType: 'int256', name: 'answer', type: 'int256' },
          { internalType: 'uint256', name: 'startedAt', type: 'uint256' },
          { internalType: 'uint256', name: 'updatedAt', type: 'uint256' },
          { internalType: 'uint80', name: 'answeredInRound', type: 'uint80' },
        ],
        stateMutability: 'view',
        type: 'function',
      },
      {
        inputs: [],
        name: 'latestRoundData',
        outputs: [
          { internalType: 'uint80', name: 'roundId', type: 'uint80' },
          { internalType: 'int256', name: 'answer', type: 'int256' },
          { internalType: 'uint256', name: 'startedAt', type: 'uint256' },
          { internalType: 'uint256', name: 'updatedAt', type: 'uint256' },
          { internalType: 'uint80', name: 'answeredInRound', type: 'uint80' },
        ],
        stateMutability: 'view',
        type: 'function',
      },
      {
        inputs: [],
        name: 'version',
        outputs: [{ internalType: 'uint256', name: '', type: 'uint256' }],
        stateMutability: 'view',
        type: 'function',
      },
    ])
  );
