import React from 'react';
import DocumentMeta from 'react-document-meta';
import '../../styles/app.css';
import '../../styles/metafans.scss';

import About from '../sections/About/AboutMF.jsx';
import Team from '../sections/About/Team.jsx';
import Signup from '../sections/About/SignupTeam.jsx';

function AboutUs() {
  const meta = {
    title: 'Who We Are | MetaFans NFT',
    description:
      'Committed to bringing people together, providing unforgettable IRL experiences. Learn about the MetaFans and our supported charities',
  };
  return (
    <>
      <DocumentMeta {...meta}></DocumentMeta>
      <About />
      <Team />
      <Signup />
    </>
  );
}

export default AboutUs;
