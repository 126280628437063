import React from 'react';
import classnames from 'classnames';
import styles from './Button.module.scss';

/**
 *
 * @param {Object} p
 * @param {Boolean} [p.isLink]
 * @param {string} [p.variant]
 * @param {string} [p.href]
 * @param {string} [p.target]
 * @param {string} [p.rel]
 * @param {React.ReactNode} [p.children]
 */
const Button = ({
  isLink,
  children,
  variant = 'O',
  href,
  target,
  rel,
  className,
  ...buttonProps
}) => {
  const classname = classnames(
    styles['base-btn'],
    'text-xs lg:text-sm',
    {
      'border-2 border-gray-500 hover:bg-white/5': variant === 'Outline',
      [`${styles['btn-gradient']} ${styles['btn-gradient-outline']}`]:
        variant === 'Gradient',
      [styles['btn-gradient-outline']]: variant === 'GradientOutline',
      'bg-primary': variant === 'Primary',
    },
    className
  );

  if (isLink) {
    return (
      <a
        href={href}
        className={classnames(classname, 'inline-block')}
        target={target}
        rel={rel}
      >
        {children}
      </a>
    );
  }

  return (
    <button className={classnames(classname)} {...buttonProps}>
      {children}
    </button>
  );
};

export default Button;
