import useEventState from './useEventState';

export const useAllowance = () =>
  useEventState('allowanceChanged', global.allowance);
export const useChain = () => useEventState('chainChanged', global.chainId);
export const useFanEpackClaims = () =>
  useEventState('fanEpackClaimsChanged', global.fanEpackClaims);
export const useLastMint = () =>
  useEventState('lastMintChanged', global.lastMint);
export const useProvider = () =>
  useEventState('providerChanged', global.provider);
export const useSigner = () => useEventState('signerChanged', global.signer);
export const useTotalSupply = () =>
  useEventState('totalSupplyChanged', global.totalSupply);
export const useWallet = () => useEventState('walletChanged', global.wallet);
