import { utils } from 'ethers';

/**
 * @param {string} message
 * @returns {SigTuple}
 */
export function split(message) {
  const rich = utils.splitSignature(message);

  return [rich.v, rich.r, rich.s];
}
