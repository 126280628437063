/**
 * @param {string} key
 */
function getValue(key) {
  const reactEnv = process.env.REACT_APP_ENV?.toUpperCase() ?? 'LOCAL';

  return process.env[`REACT_APP_${reactEnv}_${key}`];
}

const env = {
  alchemyKey: getValue('ALCHEMY_KEY'),
  apiKey: getValue('API_KEY'),
  chainId: Number(getValue('CHAIN_ID')),
  etherscanKey: getValue('ETHERSCAN_KEY'),
  fanEpacksAddress: getValue('FANEPACKS_ADDRESS'),
  infuraKey: getValue('INFURA_KEY'),
  launchAt: new Date(Number(getValue('LAUNCH_AT') ?? '') * 1_000),
  launchAtNum: Number(getValue('LAUNCH_AT') ?? ''),
  launchWalletLimit: Number(getValue('LAUNCH_WALLET_LIMIT')),
  network: getValue('NETWORK'),
  metafansAddress: getValue('METAFANS_ADDRESS'),
  baseUri: getValue('METAFANS_BASE_URI'),
  nftMaxSupply: Number(getValue('NFT_MAX_SUPPLY')),
  presaleAt: new Date(Number(getValue('PRESALE_AT') ?? '') * 1_000),
  presaleWalletLimit: Number(getValue('PRESALE_WALLET_LIMIT')),
  tokenVerificationUri: getValue('TOKEN_VERIFICATION_URI'),
  nycDueAt: new Date(Number(getValue('NYC_DUE_AT') ?? '') * 1_000),
  ethDatafeedAddress: getValue('ETH_DATAFEED_ADDRESS'),
};

global.env = env;

export default env;
