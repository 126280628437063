import React from 'react';
import Slider from 'react-slick';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import TeamMembers from './TeamMembers.jsx';
import FounderMembers from './FounderMembers.jsx';

import founderIcon from '../../../assets/images/founder-icon.svg';
import founders from '../../../assets/images/about-founders.svg';
import team from '../../../assets/images/about-team.svg';

import marlon from '../../../assets/images/marlon.jpg';
import max from '../../../assets/images/max.jpg';
import colin from '../../../assets/images/colin.jpg';
import jordan from '../../../assets/images/jordan.jpg';
import ethan from '../../../assets/images/ethan.jpg';
import mads from '../../../assets/images/mads.jpg';
import guillermo from '../../../assets/images/guillermo.jpg';
import manny from '../../../assets/images/manny.jpg';
import brent from '../../../assets/images/brent.jpg';
import brian from '../../../assets/images/brian.jpg';
import paul from '../../../assets/images/paul.jpg';
import albert from '../../../assets/images/albert.jpg';
import antonio from '../../../assets/images/antonio.jpg';
import vlad from '../../../assets/images/vlad.jpg';
import alicia from '../../../assets/images/alicia.jpg';
import placeholder1 from '../../../assets/images/team-placeholder1.jpg';
import placeholder2 from '../../../assets/images/team-placeholder2.jpg';
import placeholder3 from '../../../assets/images/team-placeholder3.jpg';
import placeholder4 from '../../../assets/images/team-placeholder4.jpg';

const Team = () => {
  return (
    <section className="about-team bg-bgGrey">
      <Tabs className="px-5 md:px-10">
        <TabList className="flex items-center justify-center team-view">
          <Tab>
            <div className="flex align-center active mr-5 team-select the-founders">
              <div>
                <img className="pb-5" src={founders} alt="founders"></img>
              </div>
              <div>
                <p className="font-bold text-textltGrey text-sm mt-1 ml-3">
                  The Founders
                </p>
              </div>
            </div>
          </Tab>
          {/* <Tab>
            <div className="flex align-center team-select the-team">
              <div>
                <img className="pb-5" src={team} alt="the team"></img>
              </div>
              <div>
                <p className="font-bold text-textltGrey text-sm mt-1 ml-3">
                  The Team
                </p>
              </div>
            </div>
          </Tab> */}
        </TabList>
        <div className="container mx-auto">
          <TabPanel>
            {/* Founders */}
            <div className="team-list flex flex-col md:flex-row justify-center items-start gap-8">
              <FounderMembers
                name="Marlon Phillips"
                imageUrl={marlon}
                title="Founder"
                linkTree="https://linktr.ee/mapcom"
                bio="Marlon brings an entrepreneurial spirit to the team and is always pushing boundaries growing within Web 3.0 space. He drives the overall vision of MetaFans, and he is the master of digital marketing for the team. Hailing from St. Louis, Missouri, Marlon is a die-hard fan of the Cardinals and the Blues."
              ></FounderMembers>
              <FounderMembers
                name="Maxfield Bala"
                imageUrl={max}
                title="Founder - Artist"
                linkTree="https://linktr.ee/maxfieldbala"
                bio="An internationally renowned illustrator and fine artist, Max is credited with bringing the MetaFans vision to life through his amazing artwork. A west coast kid, he is a fan of the San Francisco Giants, and the Golden State Warriors."
              ></FounderMembers>
              <FounderMembers
                name="Jordan Levinson"
                imageUrl={jordan}
                title="Founder"
                linkTree="https://linktr.ee/lilchuck"
                bio="Jordan has been a serial entrepreneur for over 3 decades with numerous business holdings online, in real estate, and in aviation. For most of his career, Jordan has worked alongside his MetaFans Co-Founder, Marlon. Jordan was inspired by his love of sports, live events, and NFTs, along with his extensive access to VIP experiences, in his creation of the MetaFans platform."
              ></FounderMembers>
            </div>
          </TabPanel>
          <TabPanel>
            {/* Team */}
            {/* Row 1 */}
            <div className="team-list flex flex-col md:flex-row justify-center items-start gap-8">
              <TeamMembers
                name="Ethan Andrews"
                imageUrl={ethan}
                title="Head Of Design"
                linkTree="https://linktr.ee/ethanmetafans"
              ></TeamMembers>
              <TeamMembers
                name="Madison Andrews"
                imageUrl={mads}
                title="Full Stack Developer"
                linkTree="https://linktr.ee/metamadison"
              ></TeamMembers>
              <TeamMembers
                name="Guillermo Arellano Gonzalez"
                imageUrl={guillermo}
                title="Community Manager"
                linkTree="https://linktr.ee/crypto.coach"
              ></TeamMembers>
            </div>
            {/* Row 2 */}
            <div className="team-list flex flex-col md:flex-row justify-center items-start gap-8">
              <TeamMembers
                name="Colin Asadourian"
                imageUrl={colin}
                title="Full Stack Developer"
                linkTree="https://linktr.ee/colinctss"
              ></TeamMembers>
              <TeamMembers
                name="Emmanuel Calderon"
                imageUrl={manny}
                title="Marketing / Event Operations"
                linkTree="https://linktr.ee/mannymak22"
              ></TeamMembers>
              <TeamMembers
                name="Brent Ferguson"
                imageUrl={brent}
                title="Lead Mod, Quality Assurance"
                linkTree="https://linktr.ee/pretzelogic"
              ></TeamMembers>
            </div>
            {/* Row 3 */}
            <div className="team-list flex flex-col md:flex-row justify-center items-start gap-8">
              <TeamMembers
                name="Alicia Gillentine"
                imageUrl={alicia}
                title="Communications"
                linkTree="https://linktr.ee/aliciametafans"
              ></TeamMembers>
              <TeamMembers
                name="Brian Goldberg"
                imageUrl={brian}
                title="Product Manager"
                linkTree="https://linktr.ee/bgoldberg"
              ></TeamMembers>
              <TeamMembers
                name="Paul Guarino"
                imageUrl={paul}
                title="Marketing Lead"
                linkTree="https://linktr.ee/pcg7"
              ></TeamMembers>
            </div>
            {/* Row 4 */}
            <div className="team-list flex flex-col md:flex-row justify-center items-start gap-8">
              <TeamMembers
                name="Albert Lewis"
                imageUrl={albert}
                title="Marketing and Project Management"
                linkTree="https://linktr.ee/albertiii"
              ></TeamMembers>
              <TeamMembers
                name="Antonio Manueco"
                imageUrl={antonio}
                title="Development Manager"
                linkTree="http://linktr.ee/evilmonkey"
              ></TeamMembers>
              <TeamMembers
                name="Vladimir Tudose"
                imageUrl={vlad}
                title="Lead Graphic Designer"
                linkTree="https://linktr.ee/mad_man_07"
              ></TeamMembers>
            </div>
          </TabPanel>
        </div>
      </Tabs>
    </section>
  );
};

export default Team;
