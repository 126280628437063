import React from 'react';
import { FaExclamationCircle } from 'react-icons/fa';
import Button from '../button/Button';

import env from '../../data/env';

const WrongChainButton = () => {
  return (
    <Button
      variant="Outline"
      className="text-xs lg:text-sm"
      onClick={() =>
        window.ethereum?.request({
          method: 'wallet_switchEthereumChain',
          params: [{ chainId: `0x${env.chainId.toString(16)}` }],
        })
      }
    >
      <FaExclamationCircle className="text-lg text-red-500 mr-2" />
      Wrong Chain
    </Button>
  );
};

export default WrongChainButton;
