import React from 'react';
import { Link } from 'react-router-dom';
import Button from '../../button/Button';
import dispatchEvent from '../../../fns/common/dispatchEvent';

import gen from '../../../assets/images/gen-banner.gif';
import owners from '../../../assets/images/owners-banner.gif';
import epack from '../../../assets/images/epack-banner.gif';
import metapack from '../../../assets/images/metapack-banner.gif';

const Collections = () => {
  return (
    <section className="the-collections">
      <div className="px-5 md:px-10">
        <h1 className="mt-5 mb-5 text-3xl font-bold text-center lt-white md:text-4xl">
          The Collections
        </h1>
        <div className="collections-list">
          <div className="single-collection" id="genesis">
            <div>
              <img
                className="w-full h-[380px] object-cover rounded-lg"
                src={gen}
                alt="genesis metafan"
              />
            </div>
            <div className="mt-5 mb-5">
              <h2 className="mb-3 text-3xl font-bold tracking-tight lt-white md:text-4xl">
                Genesis MetaFans Collection
              </h2>
              <p className="text-base text-grey md:text-lg">
                Each NFT is unique. Own a complete set, The Jock, The
                Cheerleader, The Die Hard, and the Average Joe, and get enhanced
                member benefits!
              </p>
            </div>
            <div className="flex flex-col items-start justify-center font-bold md:flex-row collection-stats">
              <div className="flex items-center justify-start w-full mb-5 md:mb-0 md:w-1/3 stat-item">
                <div>
                  <p className="mb-0 text-sm font-medium tracking-tight text-grey md:text-base">
                    Collection
                  </p>
                  <h2 className="mb-3 text-3xl font-bold tracking-tight lt-white md:text-4xl">
                    10k
                  </h2>
                </div>
              </div>
              <div className="flex items-center justify-start w-full mb-5 md:mb-0 md:w-1/3 stat-item">
                <div>
                  <p className="mb-0 text-sm font-medium tracking-tight text-grey md:text-base">
                    Traits
                  </p>
                  <h2 className="mb-3 text-3xl font-bold tracking-tight lt-white md:text-4xl">
                    700+ hand-drawn
                  </h2>
                </div>
              </div>
              <div className="flex items-center justify-start w-full md:w-1/3 md:justify-center stat-item">
                <div>
                  <p className="mb-0 text-sm font-medium tracking-tight text-grey md:text-base">
                    Avatars
                  </p>
                  <h2 className="mb-3 text-3xl font-bold tracking-tight lt-white md:text-4xl">
                    4
                  </h2>
                </div>
              </div>
            </div>
            <div className="mint">
              <Button
                variant="Primary"
                className=""
                onClick={() => dispatchEvent('opensaleModalRequested')}
              >
                Mint a MetaFan NFT
              </Button>
            </div>
          </div>
          <div className="single-collection" id="obg">
            <img
              src={owners}
              className="w-full h-[380px] object-cover rounded-lg"
              alt="Owners’ Box Guest"
            />
            <div className="mt-5 mb-5">
              <h2 className="mb-3 text-3xl font-bold tracking-tight lt-white md:text-4xl">
                Owner's Box Guest
              </h2>
              <p className="text-base text-grey md:text-lg">
                Included in the Genesis collection, these are the rarest and
                most valuable.
              </p>
            </div>
            <div className="flex flex-col items-start justify-center font-bold md:flex-row collection-stats">
              <div className="flex items-center justify-start w-full mb-5 md:mb-0 md:w-1/3 stat-item">
                <div>
                  <p className="mb-0 text-sm font-medium tracking-tight text-grey md:text-base">
                    Collection
                  </p>
                  <h2 className="mb-3 text-3xl font-bold tracking-tight lt-white md:text-4xl">
                    18 in 10k
                  </h2>
                </div>
              </div>
              <div className="flex items-center justify-start w-full mb-5 md:mb-0 md:w-1/3 stat-item">
                <div>
                  <p className="mb-0 text-sm font-medium tracking-tight text-grey md:text-base">
                    Traits
                  </p>
                  <h2 className="mb-3 text-3xl font-bold tracking-tight lt-white md:text-4xl">
                    unique one-of-one
                  </h2>
                </div>
              </div>
              <div className="flex items-center justify-start w-full md:w-1/3 md:justify-center stat-item"></div>
            </div>
            <div className="mint">
              <Button
                variant="Primary"
                className=""
                onClick={() => dispatchEvent('opensaleModalRequested')}
              >
                Mint a MetaFan NFT
              </Button>
            </div>
          </div>
          <div className="single-collection" id="fanepack">
            <img
              src={epack}
              className="w-full h-[380px] object-cover rounded-lg"
              alt="FanEpacks"
            />
            <div className="mt-5 mb-5">
              <h2 className="mb-3 text-3xl font-bold tracking-tight lt-white md:text-4xl">
                FanEpacks
              </h2>
              <p className="text-base text-grey md:text-lg">
                Free with each MetaFans NFT mint (*minus gas fees). FanEpack NFT's will be incorporated into RMB opportunities at a later date.
              </p>
            </div>
            <div className="flex flex-col items-start justify-center font-bold md:flex-row collection-stats">
              <div className="flex items-center justify-start w-full mb-5 md:mb-0 md:w-1/3 stat-item">
                <div>
                  <p className="mb-0 text-sm font-medium tracking-tight text-grey md:text-base">
                    Collection
                  </p>
                  <h2 className="mb-3 text-3xl font-bold tracking-tight lt-white md:text-4xl">
                    10k
                  </h2>
                </div>
              </div>
              <div className="flex items-center justify-start w-full mb-5 md:mb-0 md:w-1/3 stat-item">
                <div>
                  <p className="mb-0 text-sm font-medium tracking-tight text-grey md:text-base">
                    Traits
                  </p>
                  <h2 className="mb-3 text-3xl font-bold tracking-tight lt-white md:text-4xl">
                    400+ hand-drawn
                  </h2>
                </div>
              </div>
              <div className="flex items-center justify-start w-full md:w-1/3 md:justify-center stat-item"></div>
            </div>
            <div className="mint">
              <Button
                variant="Primary"
                className=""
                onClick={() => dispatchEvent('fanEpackModalRequested')}
              >
                Claim Your FanEpack
              </Button>
            </div>
          </div>
          <div className="single-collection" id="metapacks">
            <img
              src={metapack}
              className="w-full h-[380px] object-cover rounded-lg"
              alt="MetaPacks"
            />
            <div className="mt-5 mb-5">
              <h2 className="mb-3 text-3xl font-bold tracking-tight lt-white md:text-4xl">
                MetaPacks
              </h2>
              <p className="text-base text-grey md:text-lg">
                One-of-One FanEpack NFTs. Keep your fingers crossed when you
                mint your FanEpack, as you might acquire one of these!
              </p>
            </div>
            <div className="flex flex-col items-start justify-center font-bold md:flex-row collection-stats">
              <div className="flex items-center justify-start w-full mb-5 md:mb-0 md:w-1/3 stat-item">
                <div>
                  <p className="mb-0 text-sm font-medium tracking-tight text-grey md:text-base">
                    Collection
                  </p>
                  <h2 className="mb-3 text-3xl font-bold tracking-tight lt-white md:text-4xl">
                    22
                  </h2>
                </div>
              </div>
              <div className="flex items-center justify-start w-full mb-5 md:mb-0 md:w-1/3 stat-item">
                <div>
                  <p className="mb-0 text-sm font-medium tracking-tight text-grey md:text-base">
                    Traits
                  </p>
                  <h2 className="mb-3 text-3xl font-bold tracking-tight lt-white md:text-4xl">
                    unique one-of-one
                  </h2>
                </div>
              </div>
              <div className="flex items-center justify-start w-full md:w-1/3 md:justify-center stat-item"></div>
            </div>
            <div className="mint">
              <Button
                variant="Primary"
                className=""
                onClick={() => dispatchEvent('opensaleModalRequested')}
              >
                Mint a MetaFan NFT
              </Button>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Collections;
