import React, { Component } from 'react';
import Slider from 'react-slick';
import DeskSliderContent from './DeskSliderContent.jsx';
import MobileSliderContent from './MobileSliderContent.jsx';

import dispatchEvent from '../../../fns/common/dispatchEvent';

import genesis from '../../../assets/images/genesis1.gif';
import fanepack from '../../../assets/images/fanepack-slide.gif';
// import owners from '../../../assets/images/owners-slide.gif';
import owners from '../../../assets/images/poker.gif';
import redcard from '../../../assets/images/test1.gif';

const Genesis = () => {
  const sliderSettings = {
    dots: true,
    arrows: true,
    infinite: false,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  const mobileSliderSettings = {
    dots: true,
    arrows: false,
    infinite: false,
    slidesToShow: 1,
    slidesToScroll: 1,
  };
  return (
    <section className="genesis-slider" id="collection-slide">
      <div className="px-5 md:px-10">
        <Slider className="desktop-slider" {...sliderSettings}>
          {/* Genesis Slide */}
          {/* <DeskSliderContent
            id="genesis"
            leftHeader="Learn About Our Collections"
            centerHeader="The Genesis Collection"
            rightHeader="Genesis MetaFans"
            leftText="Collect and trade our NFT's and Enjoy the chance to win $1,000's in Ethereum!"
            slideNumber="01"
            imgUrl={genesis}
            rightText="Each NFT is unique. Own a complete set, The Jock, The Cheerleader, The Die Hard, and the Average Joe, and get enhanced member benefits!"
            cta="Mint a MetaFan NFT"
            ctaOnClick={() => dispatchEvent('opensaleModalRequested')}
          ></DeskSliderContent> */}
          {/* FanEpack Slide */}
          <DeskSliderContent
            id="fanepack"
            leftHeader="Sports Contests & Challenges with Significant ETH Payouts for the Winners!"
            centerHeader="Join Our Sports Club"
            rightHeader="Check <a class='underline' href='/nft-utilities'>NFT & Utilites</a> Section For MetaFans NFT Details"
            leftText="Every MetaFan's mint gets you Multiple Entries to our MetaFan's Contests!"
            slideNumber=""
            imgUrl={redcard}
            rightText="Each NFT is unique. Own a complete set, The Jock, The Cheerleader, The Die Hard, and the Average Joe, and get enhanced member benefits!"
            cta="Mint a MetaFan NFT"
            ctaOnClick={() => dispatchEvent('opensaleModalRequested')}
          ></DeskSliderContent>
          {/* Owners Slide */}
          {/* <DeskSliderContent
            id="owners"
            leftHeader="Mint a MetaFans NFT and get your $5.50 buyin every Wednesday for our NLH HomeGame with Betondrew all year!"
            centerHeader="Join Our Poker Club"
            rightHeader="Check How You Win Section For Details"
            leftText={"Visit <a href='https://www.betondrew.com' target='_blank' rel='noopener noreferrer'>Betondrew</a> to download and Sign-up for your ACR account!"}
            slideNumber="03"
            imgUrl={owners}
            rightText="Explore all of the MetaFans collections! Learn about the rarest and most valuable NFTs and the different traits available within each collection."
            cta="Learn More"
            ctaLink="/nft-utilities"
          ></DeskSliderContent> */}
        </Slider>
        <Slider className="py-10 mobile-slider" {...mobileSliderSettings}>
          {/* Genesis Slide */}
          {/* <MobileSliderContent
            id="genesis"
            header="The Genesis Collection"
            bodyText="Collect and trade our NFT's and Enjoy the chance to win $1,000's in Ethereum!"
            slideNumber="01"
            imageUrl={genesis}
            ctaOnClick={() => dispatchEvent('opensaleModalRequested')}
            cta="Mint a MetaFan NFT"
          ></MobileSliderContent> */}
          {/* FanEpack Slide */}
          <MobileSliderContent
            id="fanepack"
            header="Join Our Sports Club"
            bodyText="Sports Contests & Challenges with Significant ETH Payouts for the Winners!"
            slideNumber=""
            imageUrl={redcard}
            ctaOnClick={() => dispatchEvent('opensaleModalRequested')}
            cta="Mint a MetaFan NFT"
          ></MobileSliderContent>
          {/* Owners Slide */}
          {/* <MobileSliderContent
            id="owners"
            header="Join Our Poker Club"
            bodyText="Get your hands in our Poker Club!"
            slideNumber="03"
            imageUrl={owners}
            ctaLink="/nft-utilities"
            cta="Learn More"
          ></MobileSliderContent> */}
        </Slider>
      </div>
    </section>
  );
};

export default Genesis;
