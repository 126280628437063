import DocumentMeta from 'react-document-meta';
import Benefits from '../sections/Utilities/Benefits.jsx';
import Collections from '../sections/Utilities/Collections.jsx';
import Join from '../sections/Utilities/Join.jsx';
import bghtw from '../../assets/images/bghtw1.jpg';

function Utilities() {
  const meta = {
    title: 'NFT Collections & Utility | MetaFans NFT',
    description:
      'Learn about the MetaFans NFT collections. The utility is unlike any other NFT project, featuring IRL Experiences at sporting events and concerts nationwide.',
  };
  return (
    <>
      <DocumentMeta {...meta}></DocumentMeta>
      <div style={{ backgroundImage: `url(${bghtw})`, backgroundSize: 'cover', backgroundPosition: 'center' }}>
      {/* <Benefits /> */}
      <Collections />
      {/* Needs Work (slider) */}
        <Join />
      </div>
    </>
  );
}

export default Utilities;
