import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import env from '../../data/env';
import {
  useChain,
  useFanEpackClaims,
  useWallet,
} from '../../hooks/useEventStates';
import { wei } from '../../utils/units';
import MetaConnect from '../ConnectButton/MetaConnect';

const ThankYou = () => {
  const wallet = useWallet();
  const chain = useChain();
  const claims = useFanEpackClaims();
  const navigate = useNavigate();
  const [nftUri, setNftUri] = useState('');

  useEffect(() => {
    function redirectHome() {
      navigate('/');
    }

    if (!wallet) {
      redirectHome();
      return;
    }

    const baseURL = `https://eth-${
      chain === 1 ? 'mainnet' : 'rinkeby'
    }.alchemyapi.io/v2/${env.alchemyKey}/getNFTs/`;
    const fetchURL = `${baseURL}?owner=${wallet}&contractAddresses[]=${env.metafansAddress}&withMetadata=false`;

    fetch(fetchURL, {
      method: 'GET',
      redirect: 'follow',
    })
      .then((response) => response.json())
      .then((response) => JSON.stringify(response, null, 2))
      .then((result) => {
        const res = JSON.parse(result);

        if (!res.ownedNfts?.length) {
          redirectHome();
          return;
        }

        const tokenId = Math.max(
          ...res.ownedNfts.map((/** @type {any} */ item) =>
            wei(item.id.tokenId).toNumber()
          )
        );
        setNftUri(`${env.baseUri}/${tokenId}.png`);
      })
      .catch((error) => {
        console.error('error', error);
        redirectHome();
      });
  }, [chain, navigate, wallet]);

  return (
    <>
      <section className="bg-thankYou bg-cover w-full h-[20vh] md:h-[35vh] 2xl:h-[40vh] bg-center"></section>
      <section className="thank-you bg-dark1">
        <div className="py-5">
          <div className="container mx-auto">
            <h1 className="text-4xl font-black text-center lt-white sm:text-5xl lg:text-6xl px-30 md:px-10 xl:px-60">
              Congratulations on minting your MetaFans NFT!
            </h1>
            <h2 className="mt-3 text-xl font-black text-center sm:text-3xl text-grey">
              Getting started is easy.
            </h2>
            <div className="md:px-20 xl:px-40">
              <p className="mt-5 text-base font-medium text-center md:text-xl text-grey">
                You are now part of an active and growing community of sports
                fans where you can share your love of sports and enjoy IRL
                experiences!
              </p>
              <p className="mt-5 text-base font-medium text-center md:text-xl text-grey">
                As a MetaFan NFT holder, you have additional perks via our <a
                  className="mb-2 underline "
                  href="https://discord.gg/metafans"
                  target="_blank"
                >Discord!</a>, where you can
                participate in weekly online games and win big prizes!
              </p>
            </div>
          </div>
          <div className="px-10 py-8 mx-auto mt-12 border-2 bg-bgGrey border-ltGrey shadow-gray-900 rounded-2xl w-fit">
            <p className="mb-3 text-sm font-bold text-center text-white">
              See what the hype is all about
            </p>
            <MetaConnect />
          </div>
        </div>
      </section>
    </>
  );
};

export default ThankYou;
