import React from 'react';
import { Link } from 'react-router-dom';
import Navbar from '../Navbar/Navbar.jsx';
import logo from '../../assets/images/logo.png';
import PrimaryActionButton from '../PrimaryActionButton/PrimaryActionButton.jsx';

const Header = () => {
  return (
    <div className="px-5 bg-dark1 border-b-1 border-b-gray-800 md:px-10">
      <div className="flex items-center justify-start lg:justify-between">
        <div className="block w-1/3 lg:hidden">
          <Navbar />
        </div>
        <div className="block w-1/3 lg:hidden">
          <Link to="/">
            <img
              src={logo}
              className="logo w-[9rem] ml-6 py-3"
              alt="metafans logo"
            />
          </Link>
        </div>
        <div className="block w-1/3 lg:hidden"></div>
        <div className="flex-col-reverse items-center justify-center block w-1/3 gap-4 lg:hidden">
          <PrimaryActionButton />
        </div>
        <div className="hidden w-auto lg:block">
          <Link to="/">
            <img
              src={logo}
              className="logo w-[9rem] md:w-[12rem]"
              alt="metafans logo"
            />
          </Link>
        </div>
        {/* <div className="w-1/2 lg:hidden"></div> */}
        <div className="items-center justify-center hidden w-autogap-4 lg:flex lg:flex-row lg:justify-end">
          <Navbar />
          <PrimaryActionButton />
        </div>
      </div>
    </div>
  );
};

export default Header;
