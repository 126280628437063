import contractWallet from '../contracts/contractWallet';

const contractWalletObserver = {
  listen: function () {
    window.addEventListener('walletChanged', (ev) =>
      contractWallet.connect(ev.detail)
    );
  },
};

export default contractWalletObserver;
