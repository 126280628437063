import { useEffect } from 'react';

/**
 * @param {string} targetKey
 * @param {(ev: KeyboardEvent) => void} listener
 * @param {import("react").DependencyList} [deps]
 */
export default function useKeyListener(targetKey, listener, deps = []) {
  let mainKey = targetKey;
  let shortcutKey = '';

  if (targetKey.includes('+')) {
    [shortcutKey, mainKey] = targetKey.split('+');
  }

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    const outerListener = (/** @type {any} */ ev) => {
      if (ev.key !== mainKey) {
        return;
      }

      if (shortcutKey && ev[shortcutKey + 'Key'] !== true) {
        return;
      }

      ev.preventDefault();
      listener(ev);
    };

    window.addEventListener('keydown', outerListener);

    return () => window.removeEventListener('keydown', outerListener);
  }, [deps]);
}
