import { Web3Provider } from '@ethersproject/providers';
import WalletConnectProvider from '@walletconnect/web3-provider';

import env from '../../data/env';
import dispatchEvent from '../common/dispatchEvent';
import dispatchPixelEvent from '../common/dispatchPixelEvent';
import walletUtils from '../common/walletUtils';

/**
 * @param {string | undefined} walletish
 */
const dispatchWalletChanged = async function (walletish) {
  const canon = await walletUtils.canon(walletish);

  dispatchEvent('walletChanged', canon);
  dispatchPixelEvent('ConnectWallet');
};

const walletConnect = {
  connect: async function () {
    if (global.wallet) {
      return;
    }

    const provider = new WalletConnectProvider({
      infuraId: env.infuraKey,
    });

    global.walletConnectProvider = provider;

    const [account] = await provider.enable();

    provider.on('accountsChanged', (/** @type {string[]} */ accounts) =>
      dispatchWalletChanged(accounts[0])
    );

    provider.on('chainChanged', (/** @type {number} */ chainId) =>
      dispatchEvent('chainChanged', chainId)
    );

    dispatchWalletChanged(account);
    dispatchEvent('chainChanged', provider.chainId);

    const ethersProvider = new Web3Provider(provider);
    const signer = ethersProvider.getSigner();

    dispatchEvent('signerChanged', signer);
  },
};

export default walletConnect;
