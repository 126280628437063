import { useCallback, useEffect, useState } from 'react';

import { add, minutesToSeconds } from 'date-fns';

import { useLastMint } from './useEventStates';

const cooldownDuration = minutesToSeconds(15); // 15 minutes

export default function useMintCountdown() {
  const lastMint = useLastMint();
  const [countdownMs, setCountdownMs] = useState(0);

  const updateCountdown = useCallback(() => {
    const cooldownEnd = add(lastMint ?? new Date(0), {
      seconds: cooldownDuration,
    });

    const countdownLeft = cooldownEnd.valueOf() - new Date().valueOf();

    if (countdownLeft <= 0) {
      setCountdownMs(0);

      return 0;
    }

    setCountdownMs(countdownLeft);

    return countdownLeft;
  }, [lastMint]);

  useEffect(() => {
    /** @type {NodeJS.Timer} */
    let intervalId;

    intervalId = setInterval(() => {
      const countdownLeft = updateCountdown();

      if (countdownLeft === 0) {
        clearInterval(intervalId);
      }
    }, 500);

    return () => clearInterval(intervalId);
  }, [updateCountdown]);

  useEffect(() => {
    updateCountdown();
  }, [updateCountdown]);

  return countdownMs;
}
