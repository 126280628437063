import { ethers } from 'ethers';

import { metafansContract } from '../../data/contracts';
import dispatchEvent from '../common/dispatchEvent';

const contractAggregate = {
  connect: async function () {
    if (!metafansContract) {
      return;
    }

    const contract = metafansContract.connect(global.provider);

    const syncTotalSupply = async () => {
      const totalSupply = await contract.totalSupply();

      dispatchEvent('totalSupplyChanged', totalSupply.toNumber());
    };

    const mintFilter = contract.filters.Transfer(ethers.constants.AddressZero);

    contract.on(mintFilter, syncTotalSupply);

    await syncTotalSupply();
  },
};

export default contractAggregate;
