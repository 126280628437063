import React from 'react';

function TaxForm() {
  return (
    <section className="tax-form">
      <div className="container mx-auto py-10">
        <div class="klaviyo-form-V9vVCL"></div>
      </div>
    </section>
  );
}

export default TaxForm;
