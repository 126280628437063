import React from 'react';
import DocumentMeta from 'react-document-meta';
import '../../styles/app.css';
import '../../styles/metafans.scss';

import About from '../sections/About/AboutMF.jsx';
import Team from '../sections/About/Team.jsx';
import Signup from '../sections/About/SignupTeam.jsx';
import '../../styles/app.css';
import '../../styles/metafans.scss';
import bghtw from '../../assets/images/bghtw1.jpg';
import HowToWinCard from '../cards/HowToWinCard.jsx';
import team from '../../assets/images/meet-the-team.jpg';
import HallOfFame from '../../assets/images/halloffame.svg';

function AboutMetafans() {
  const cardData = [
    {
      title: 'Level 1: Holders with 1 FULL SET of all 4 MetaFans NFT Characters receive:',
      items: ['Weekly Buyin $5.50 NLH PKO “BetonDrew” Home Game Wednesdays at 8 p.m. EST', '1 square for all MetaFans NFL Pro & NCAA College Primetime Game Square Pools', '1 bracket for the NCAA Basketball March Madness Challenge', '1 team entry PGA Major Golf challenge', '1 entry NBA & NHL Playoff challenges'],
      flipItems: ['All participants must create a DraftKings account.', 'Anyone who enters a league they are not eligible for will forfeit prizes for all leagues that week.', 'All prizes for leagues TBD by management'],
    },
    {
      title: 'Level 2: Holders with 3 FULL SETS of all 4 MetaFans NFT Characters receive:',
      items: ['Weekly Buyin $5.50 NLH PKO “BetonDrew” Home Game Wednesdays at 8 p.m. EST', 'Weekly Buyin $16.50 NLH MetaFans Open Home Game Sundays 7pm EST at ACR Poker', '3 squares for All MetaFans NFL Pro & NCAA College Primetime Game Square Pools', '3 brackets for the NCAA Basketball March Madness Challenge', '3 team entries for PGA Major Golf tournament challenge', '3 entries into the NBA & NHL Playoffs challenges'],
      flipItems: ['All participants must create a DraftKings account.', 'Anyone who enters a league they are not eligible for will forfeit prizes for all leagues that week.', 'All prizes for leagues TBD by management'],
    }
  ];
  const meta = {
    title: 'About MetaFans | MetaFans NFT',
    description:
      'Committed to bringing people together, providing unforgettable IRL experiences. Learn about the MetaFans and our supported charities',
  };
  return (
    <>
      <DocumentMeta {...meta}></DocumentMeta>
      <div className="theme-dk-blue" style={{ backgroundImage: `url(${bghtw})`, backgroundSize: 'cover', backgroundPosition: 'center' }}>
        <About />
        <div className="flex flex-col items-center px-6 mt-10 mb-5 md:px-10 md:flex-row">
          <div className="w-full mr-3 md:px-10 md:w-6/12">
            <h1 className="mb-5 text-4xl font-bold tracking-tight lt-white lg:text-4xl">
              MetaFans Alumni Benefits
            </h1>
          </div>
        </div>
        <div className="flex items-center justify-center bg-gray-900 " style={{ backgroundImage: `url(${bghtw})`, backgroundSize: 'cover', backgroundPosition: 'center' }}>
          <div className="grid w-full grid-cols-1 gap-4 mx-5 md:mx-10 md:px-10 md:grid-cols-2">
            {cardData.map((card, index) => (
              <HowToWinCard key={index} titleFont='text-xl md:text-2xl' minHeight="min-h-[650px] md:min-h-[450px]" descFont='text-lg text-white/80' title={card.title} items={card.items} flipItems={card.flipItems} />
            ))}
            {/* <div className="relative w-full shadow-lg htw-card flip-card">
              <div class="flip-card-inner">
                <div className={`first-view p-4 md:p-10 flip-card-front`}>
                  <h2 class="mb-2 text-4xl font-semibold text-center text-white">Coming Soon</h2>
                </div>
              </div>
            </div> */}
          </div>
        </div>
        <div className="flex flex-col items-center px-6 mt-10 md:px-10 md:flex-row">
          <div className="w-full mr-3 md:px-10 md:w-6/12">
            <h1 className="mb-5 text-4xl font-bold tracking-tight lt-white lg:text-4xl">
              MetaFans Hall of Famer Benefits
            </h1>
            {/* <h2 className="mb-4 text-xl font-bold tracking-tight text-grey lg:text-2xl">
              Learn about our team.
            </h2> */}
            <p className='mt-4 mb-4 text-md md:text-lg text-white/80'><strong>Anyone who Owns, Mints or Purchases 1 of the 18 Owner’s Box Guest Characters (OBGs)</strong> will receive <strong>ALL MetaFans Benefits, PLUS, AN ALL-EXPENSE PAID TRIP FOR 2</strong> (4 Days & 3 Nights) for the 2025 Super Bowl Watch Party held at the Ceasars Hotel & Casino Ballroom in Las Vegas, as guests of CryptoDaddio &  other MetaFans Holders, <strong>OR</strong> a trip to Play Poker in a WSOP Tournament next Summer in the WSOP Las Vegas (Estimated vacation package value of $7,500)!</p>

          </div>
          <div className="w-full md:px-10 md:w-6/12">
            <img
              className="mx-auto"
              src={HallOfFame}
              alt="meet the metafans team"
            ></img>
          </div>
        </div>
      </div>


      {/* <Team />
      <Signup /> */}
    </>
  );
}

export default AboutMetafans;
