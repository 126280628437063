import React from 'react';

function Terms() {
  return (
    <section className="terms-conditions">
      <div className="container mx-auto">
        <h1 className="lt-white font-bold">Terms & Conditions</h1>
        <h1 className="lt-white font-bold">of Use</h1>
        <p className="text-grey">Last updated: November 26, 2021</p>

        <p className="text-grey">
          Before using metafans.com (the “Site”), owned and operated by MetaFans
          Media, LLC, a Florida Limited Liability Company with a principal
          address of 6560 W. Rogers Circle, Ste. 15, Boca Raton, FL 33487
          (“MetaFans,” “we,” “our,” or “us”), please review the following terms
          and conditions (the “Terms”) carefully, INCLUDING THE BINDING
          ARBITRATION PROVISON CONTAINED BELOW. The Terms are a legal agreement
          between MetaFans and you, whether you are a visitor or a member
          (“Member”). By accessing, browsing, or using the Site or any of our
          services you acknowledge that you have read, understood, and agree to
          comply with and be bound by the Terms (the “Agreement”), including
          those additional terms and conditions referenced in the Site and/or
          available by link. Your use of the Site is also conditioned on your
          acceptance of our Privacy Policy which can be found at the footer of
          this webpage. If you do not agree with any part of these Terms or the
          Privacy Policy, do not use the Site.
        </p>

        <p className="text-grey">
          YOU AGREE THAT BY USING THE SITE YOU ARE AT LEAST 18 YEARS OF AGE AND
          LEGALLY ABLE TO ENTER INTO A CONTRACT.
        </p>

        <p className="text-grey">Our Services</p>
        <p className="text-grey">
          We provide you, our users, access to information regarding private
          digital collections of MetaFans NFTs, stored as ERC-721 tokens on the
          Ethereum blockchain, as well as corresponding membership benefits that
          issue upon owning a MetaFans NFT. Further, we allow you to sign up for
          our messaging program to be one of the first to know about our
          promotions via text. In addition, we allow users to view and purchase
          MetaFans NFTs available for sale. We refer to the foregoing as our
          “Services.”
        </p>

        <p className="text-grey">NFT Purchases and Payments</p>
        <p className="text-grey">
          When you are the initial purchaser of a MetaFans NFT through the
          Services, we will mint the NFT through a third-party NFT marketplace
          (i.e., a public blockchain) to demonstrate rights ownership and
          authenticity of the NFT’s underlying artwork and then transfer the
          ownership rights of the NFT to you upon completion of your purchase
          (see the “Ownership Rights” section below for more information).
          Please note, all post- minting purchases of MetaFans NFTs through the
          Services are made via links to third-party NFT marketplaces. Such
          links are available on the Site for your convenience only, we are
          solely an intermediary between you and such marketplaces. As such, you
          assume all risks and are entirely responsible for all activity
          conducted with such marketplaces, including purchasing NFTs and
          validating any related contracts. Because the purchase and ownership
          of an NFT is entirely accomplished by a self- executing smart contract
          running on a blockchain,{' '}
          <strong>
            we have no ability to reverse a purchase or refund a payment once
            executed
          </strong>
          . Any purchase or payment issues are your sole responsibility.
        </p>

        <p className="text-grey">Ownership Rights</p>
        <p className="text-grey">
          When you purchase a MetaFans NFT, you are the owner of that digital
          asset, and thus have the right to transfer, sell, or trade the NFT.
          However, you do not acquire interest in the copyright of the
          underlying artwork solely by purchasing an NFT. For example, this is
          similar to where an art collector purchases a painting, the collector
          owns the physical painting; however, absent a separate transfer of the
          copyright, acquires no interest in the copyright.
        </p>

        <p className="text-grey">
          MetaFans, as the copyright holder, maintains all commercial rights to
          the artwork underlying the MetaFans NFTs. This is what gives us the
          right, for example, to display the artwork on the Site, market the
          artwork by making merch, and mint the digital versions of the artwork.
          As such, you may not reproduce, distribute, adapt, modify, create
          derivative works from, publish, publicly display, or otherwise use the
          underlying artwork of a MetaFans NFT that you own in any commercial
          way, except as expressly permitted by these Terms.
        </p>

        <p className="text-grey">Acceptable Use of the Services</p>
        <p className="text-grey">
          In connection with your access and use of the Services, you shall not:
          (a) engage in any activity that could damage, disable, impair,
          interrupt, or interfere with the Services, including networks and
          servers connected to the Services; (b) introduce a virus, worm, trojan
          horse, or any other malicious or harmful software code, data, or file
          that may damage, interfere with, intercept or expropriate any system,
          data, personal information or property of another; (c) attempt to gain
          unauthorized access to accounts not owned by you, or to any connected
          network or servers to the Services through hacking, phishing, or other
          means; (d) host the Services to be accessible by third parties; (e)
          sell, resell, transfer, assign, license, lease, or rent access to or
          use of the Services or otherwise transfer any rights to use the
          Services under this Agreement; (f) utilize the Services in connection
          with any activity that would constitute a violation of any applicable
          law, regulation or ordinance; (e) obtain or attempt to obtain any
          information through any means not intentionally made available through
          the Services; (f) defame, defraud, abuse, harass, stalk, threaten, or
          otherwise violate any legal rights (such as rights of publicity and
          privacy) of others; (g) transmit any files or materials protected by
          intellectual property laws, unless you own or control the rights
          thereto or have received the necessary consent to do so; (h) utilize
          “scripts,” “robots,” “scrapers,” “spiders,” “web crawlers,” “data
          mining tools,” “extraction tools,” or use computer programs that
          recursively query the Site over the Internet; (i) frame, cache, grab,
          use, or copy any content included on the Site or Services; (j) reverse
          engineer the Site or content thereon for any purpose whatsoever.
        </p>

        <p className="text-grey">
          You acknowledge that you are solely responsible for any breach of the
          obligations under this Agreement and for the consequences of any such
          breach, including any loss of damage which we may suffer.
        </p>

        <p className="text-grey">Intellectual Property</p>
        <p className="text-grey">
          We retain ownership and control of all intellectual property inherent
          in, associated with, or related to the Site and Services including,
          but not limited to, copyrights, patent rights, trademarks, and trade
          dress rights, whether registered or unregistered, arising under
          Federal, State, or common law, as well as confidential or commercially
          sensitive information, such as trade-secrets, and any other right not
          expressly licensed to you under this Agreement. You acknowledge that
          no ownership rights are being conveyed to you under this Agreement.
        </p>

        <p className="text-grey">
          From time-to-time you may provide us feedback, suggestions, or
          comments regarding the Services (“Feedback”). You agree to grant us a
          non-exclusive, worldwide, perpetual, irrevocable, transferable,
          royalty-free, license to use the Feedback for the purpose of improving
          or promoting the Services.
        </p>

        <p className="text-grey">Information You Provide</p>
        <p className="text-grey">
          Any information you provide in connection with the use of the
          Services, such as those relating to your account is your sole
          responsibility. This means that you are responsible for the accuracy
          of all the information that you provide us through the Services. Under
          no circumstances will we be liable for any errors or omissions with
          respect to such information, or for damages of any kind incurred as a
          result of such errors or omissions. You agree that you bear all risks
          associated with the use of any information you provide us including,
          but not limited to, our reliance on the accuracy, legality,
          timeliness, or completeness of such information.
        </p>

        <p className="text-grey">Third Party Links</p>
        <p className="text-grey">
          Our Site contains links to third-party websites that are not owned by
          us, such as third-party NFT marketplaces mentioned above. We have no
          control over the policies or practices of any such third-party
          website, including any content provided by such third-party that may
          be displayed, included, or made available through the Services. As
          such, we assume no responsibility for any damages you incur in
          connection with such third-party websites. Your correspondence and
          business dealings with any third parties via the Services are solely
          between you and such third parties. You should read the terms and
          conditions, privacy policies, etc. of any third-party website prior to
          using third-party services.
        </p>

        <p className="text-grey">Message Program</p>
        <p className="text-grey">
          By providing your cell phone number, you agree to receive recurring
          automated promotional and personalized marketing text messages (e.g.,
          SMS/MMS) from us. These messages include text messages that may be
          sent using an automatic telephone dialing system, to the mobile
          telephone number you provided when signing up or any other number that
          you designate. You give MetaFans permission to send text messages to
          the enrolled cell phone number through your wireless phone carrier,
          unless and until you end permission per these Terms. Consent to
          receive automated marketing text messages is not a condition of any
          purchase. Message and data rates may apply.
        </p>

        <p className="text-grey">
          Message frequency may vary. We reserve the right to alter the
          frequency of messages sent at any time, to increase or decrease the
          total number of sent messages. We also reserve the right to change the
          long code, short code, or phone number from which messages are sent
          and we will notify you if we do so.
        </p>

        <p className="text-grey">
          Not all mobile devices or handsets may be supported, and our messages
          may not be deliverable in all areas. MetaFans, our service providers,
          and the mobile carriers supported by the messaging program are not
          liable for delayed or undelivered messages. By enrolling in our
          messaging program, you agree to these Terms.
        </p>

        <p className="text-grey">Cancellation</p>
        <p className="text-grey">
          Text the keyword STOP, STOPALL, END, CANCEL, UNSUBSCRIBE, or QUIT to
          the telephone number, long code, or short code that sends you our
          initial confirmation message to cancel. After texting STOP, STOPALL,
          END, CANCEL, UNSUBSCRIBE, or QUIT to the telephone number, long code,
          or short code that sends you our initial confirmation message, you
          will receive one additional message confirming that your request has
          been processed. If you change your preferences, it may take up to 48
          hours for it to take effect. You acknowledge that our text message
          platform may not recognize and respond to unsubscribe requests that do
          not include the STOP, STOPALL, END, CANCEL, UNSUBSCRIBE, or QUIT
          keyword commands and agree that MetaFans and our service providers
          will have no liability for failing to honor such requests. If you
          unsubscribe from one of our text message programs, you may continue to
          receive text messages from us through any other programs you have
          joined until you separately unsubscribe from those programs.
        </p>

        <p className="text-grey">Help or Support</p>
        <p className="text-grey">
          Text the keyword HELP to the telephone number, long code, or short
          code that sends you our initial confirmation message to receive a text
          message with information on how to unsubscribe.
        </p>

        <p className="text-grey">Termination</p>
        <p className="text-grey">
          We may terminate this Agreement and your access to the Site at any
          time, in our sole discretion, without prior notice.
        </p>

        <p className="text-grey">Changes to the Services and Agreement</p>
        <p className="text-grey">
          We may change the Terms from time to time, at our sole discretion.
          Changes to the Terms are effective when they are posted on this
          webpage as of the “Last Modified” date. Your continued use of the Site
          after a change to the Terms constitutes your acceptance of the changes
          thereto. If you do not agree to be bound by the new Terms, as
          modified, you may not use the Site.
        </p>

        <p className="text-grey">Warranty and Warranty Disclaimer</p>
        <p className="text-grey">
          The parties represent and warrant that each has the power and
          authority to enter into this Agreement and to perform its obligations
          hereunder and that in doing so will not conflict with any other
          agreement. MetaFans warrants that the Services do not knowingly
          infringe the intellectual property of any third-party.
        </p>

        <p className="text-grey">
          THE SITE AND SERVICES ARE PROVIDED ON AN “AS IS,” “AS AVAILABLE”
          BASIS. WE DO NOT WARRANT THAT THE USE OF THE SITE AND/OR SERVICES WILL
          BE UNINTERRUPTED OR ERROR-FREE. WE DO NOT WARRANT THE ACCURACY,
          INTEGRITY OR COMPLETENESS OF THE CONTENT PROVIDED ON THE SITE OR THE
          PRODUCTS OFFERED FOR SALE THEREON, INCLUDING ANY CONTENT, PRODUCTS, OR
          SERVICES PROVIDED BY THIRD PARTEIS. FURTHER, WE MAKE NO REPRESENTATION
          THAT CONTENT PROVIDED ON THE SITE IS APPLICABLE OR APPROPRIATE FOR USE
          IN LOCATIONS OUTSIDE OF THE UNITED STATES. WE SPECIFICALLY DISCLAIM
          WARRANTIES OF ANY KIND, EITHER EXPRESSED OR IMPLIED, INCLUDING, BUT
          NOT LIMITED TO WARRANTIES OF TITLE OR IMPLIED WARRANTIES OF
          MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, OR
          NON-INFRINGEMENT, RELATED TO THE SITE AND ANY CONTENT, DATA, OR
          INFORMATION CONTAINED ON THE SITE, OR ANY BREACH OF SECURITY
          ASSOCIATED WITH THE TRANSMISSION OF PERSONAL INFORMATION THROUGH THE
          SITE OR ANY LINKED WEBSITES. NO ORAL ADVICE OR WRITTEN INFORMATION
          GIVEN BY US SHALL CREATE A WARRANTY.
        </p>

        <p className="text-grey">Limitation of Liability</p>
        <p className="text-grey">
          YOU EXPRESSLY AGREE THAT USE OF THE SERVICES IS AT YOUR SOLE RISK.
          UNDER NO CIRCUMSTANCES SHALL WE, OUR DIRECTORS, OFFICERS, EMPLOYEES OR
          AGENTS BE LIABLE FOR ANY DIRECT, INDIRECT, INCIDENTAL, SPECIAL,
          CONSEQUENTIAL, OR PUNITIVE DAMAGES THAT RESULT FROM THE USE OF OR
          INABILITY TO USE THE SERVICES, INCLUDING BUT NOT LIMITED TO RELIANCE
          BY YOU ON ANY INFORMATION OBTAINED AT THE SITE, OR THAT RESULTS FROM
          MISTAKES, OMISSIONS, INTERRUPTIONS, DELETION OF FILES OR EMAILS,
          ERRORS, DEFECTS, VIRUSES, INCLUDING, WITHOUT LIMITATION, COMPUTER
          “VIRUSES”, “WORMS”, “BUGS”, DELAYS IN OPERATION OR TRANSMISSION, OR
          ANY FAILURE OF PERFORMANCE, WHETHER OR NOT RESULTING FROM ACTS OF GOD,
          COMMUNICATIONS FAILURE, THEFT, DESTRUCTION OR UNAUTHORIZED ACCESS TO
          OUR RECORDS, PROGRAMS OR SERVICES. THE FOREGOING LIMITATION OF
          LIABILITY SHALL APPLY WHETHER IN AN ACTION OF CONTRACT, NEGLIGENCE OR
          OTHER TORTIOUS ACTION, EVEN IF AN AUTHORIZED REPRESENTATIVE OF OURS
          HAS BEEN ADVISED OF OR SHOULD HAVE KNOWLEDGE OF THE POSSIBILITY OF
          SUCH DAMAGES. YOU HEREBY ACKNOWLEDGE THAT THIS PARAGRAPH SHALL APPLY
          TO ALL CONTENT AND PRODUCTS AVAILABLE THROUGH THE SERVICES. SOME
          JURISDICTIONS DO NOT ALLOW THE EXCLUSION OR LIMITATION OF LIABILITY
          FOR CONSEQUENTIAL OR INCIDENTAL DAMAGES, IN SUCH JURISDICTIONS
          LIABILITY IS LIMITED TO THE FULLEST EXTENT PERMITTED BY LAW. THESE
          LIMITATIONS WILL APPLY NOTWITHSTANDING ANY FAILURE OF ESSENTIAL
          PURPOSE OF ANY LIMITED REMEDY.
        </p>

        <p className="text-grey">Binding Arbitration and Choice of Law</p>
        <p className="text-grey">
          You and MetaFans agree that any dispute, claim, or controversy arising
          out of or related to this Agreement, or our Privacy Policy, Site, or
          Services shall be settled by final and binding arbitration in
          accordance with the rules of the American Arbitration Association
          (“AAA”), rather than in a court of law. You agree that you are
          knowingly and voluntarily giving up your right to a trial and have
          your dispute heard by a judge or jury in a court of law. Any such
          dispute, claim, or controversy shall be arbitrated on an individual
          basis and shall not be joined or consolidated with a claim of any
          other party or class. You agree that the arbitrator’s decision shall
          be controlled by this Agreement and any other agreement that you have
          with us. You agree that the arbitrator’s award shall be final and
          binding and may be entered as a judgment in any court of competent
          jurisdiction. The arbitration will be administered by the AAA,
          pursuant to the rules of the AAA. For more information on the rules,
          procedures, and fees of the AAA, please visit their website at
          www.adr.org. The foregoing shall not preclude MetaFans from seeking
          any injunctive or other equitable relief in State or Federal court for
          protection of intellectual property rights.
        </p>

        <p className="text-grey">
          This Agreement, including binding arbitration, will be governed by the
          law of the State of Florida, without regard to the conflict of laws
          principles thereof. If any dispute, controversy, or claim cannot be
          resolved in binding arbitration between the parties, then it shall be
          submitted for resolution to a court of competent jurisdiction in
          Miami-Dade County, Florida, and the Federal Courts in and for the
          Southern District of Florida, and the parties hereby submit that
          exclusive venue shall lie with such courts, and the parties hereby
          agree to submit to the jurisdiction and venue of such courts.
        </p>

        <p className="text-grey">Entire Agreement</p>
        <p className="text-grey">
          This Agreement sets forth the entire agreement between you and
          MetaFans regarding the use of our Services. You agree that there are
          no representations or obligations regarding your use of the Services,
          other than those reflected in this Agreement, and that you are not
          relying on any representations or obligations regarding your use other
          than those reflected in this Agreement.
        </p>

        <p className="text-grey">Severability</p>
        <p className="text-grey">
          If any provision of this Agreement is held illegal or unenforceable in
          any proceeding, such provision shall be severed and shall be
          inoperative, and, provided that the fundamental terms and conditions
          of this Agreement remain legal and enforceable, the remainder of this
          Agreement shall remain in full force and effect.
        </p>

        <p className="text-grey">Force Majeure</p>
        <p className="text-grey">
          Any failure or delay in performance by either party shall be excused
          if and to the extent caused by an Act of God (fire, flood, earthquake,
          storm, hurricane, or other natural disaster), war or civil disorder,
          invasion, act of foreign enemies, hostilities, terrorism, government
          actions, pandemics, lockout or interruption or failure of electricity
          of network services, or other causes beyond the reasonable control of
          the parties.
        </p>

        <p className="text-grey">Consent to Data Usage</p>
        <p className="text-grey">
          By using the Services, you agree that you have read our Privacy
          Policy, that you understand our Privacy Policy, and that you consent
          to the collection of data and other personal information under the
          terms of our Privacy Policy.
        </p>
      </div>
    </section>
  );
}

export default Terms;
