import React, { useState } from 'react';
import classnames from 'classnames';
import { isDesktop } from 'react-device-detect';
import { IoCloseOutline, IoCheckmark } from 'react-icons/io5';
import ConnectButton from '../ConnectButton/ConnectButton';
import InstallButton from '../InstallButton/InstallButton';

import { BigNumber } from 'ethers';
import { toast } from 'react-toastify';
import Button from '../button/Button';
import Input from '../input/Input';

import { fanEpacksContract } from '../../data/contracts';
import {
  useFanEpackClaims,
  useSigner,
  useWallet,
  useChain,
  useTotalSupply,
} from '../../hooks/useEventStates';
import { split } from '../../utils/sig';
import { wei } from '../../utils/units';

/**
 * @param {Object} p
 * @param {() => void} p.onCancel
 */
export default function FanEpackCard(p) {
  const claims = useFanEpackClaims() ?? { allowance: 0, claimed: 0, earned: 0 };

  const [quantity, setQuantity] = useState('1');

  const signer = useSigner();
  const wallet = useWallet();

  const account = useWallet();
  const chainId = useChain();
  const totalSupply = useTotalSupply();

  const quantityNum = Number(quantity);

  const invalidQuantityNum = isNaN(quantityNum);

  const isMetaMaskInstalled = !!window.ethereum?.isMetaMask;

  let errorMessage;

  if (!claims.allowance) {
    errorMessage = 'All earned FanEpacks have been claimed';
  } else if (!quantity || quantityNum < 1) {
    errorMessage = 'Please claim at least one';
  } else if (invalidQuantityNum) {
    errorMessage = 'Must be a number';
  } else if (quantityNum > claims.allowance) {
    errorMessage = `Only ${claims.allowance} can be claimed`;
  }

  const handleSubmit = async (ev) => {
    ev.preventDefault();

    if (!fanEpacksContract) {
      toast.error('Not connected to the network. Please refresh.');
      return;
    }

    const msgRes = await fetch(`/api/claims/fanepacks/sig/${wallet}`);
    const msg = await msgRes.text();

    const frozenQuantity = quantityNum;

    fanEpacksContract
      .connect(signer)
      .claim(wei(frozenQuantity), BigNumber.from(claims.earned), ...split(msg))
      .then((tx) => {
        const toastId = toast.loading(
          <div className="flex flex-row items-center">
            Claim FanEpack transaction submitted for {frozenQuantity} NFT
            {frozenQuantity === 1 ? '' : 's'}
          </div>
        );

        return tx
          .wait()
          .then(() =>
            toast.update(toastId, {
              render: (
                <div className="flex flex-row items-center">
                  Claim FanEpack confirmed for {frozenQuantity} NFT
                  {frozenQuantity === 1 ? '' : 's'}
                </div>
              ),
              type: 'success',
              isLoading: false,
              closeButton: true,
              closeOnClick: true,
            })
          )
          .catch((e) =>
            toast.update(toastId, {
              render: e.message,
              type: 'error',
              isLoading: false,
              closeButton: true,
              closeOnClick: true,
            })
          )
          .finally(() => p.onCancel());
      })
      .catch(() =>
        toast.error('Unexpected error while trying to submit a transaction')
      );
  };

  if (!(account && chainId)) {
    if (isMetaMaskInstalled) {
      return (
        <>
          <div
            className={classnames(
              'inline-block align-bottom bg-dark1 rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:w-full sm:max-w-lg p-5 text-center'
            )}
          >
            <h3 className="text-3xl font-bold text-white">
              Oops – It Looks Like Your Wallet Is Not Connected!
            </h3>
            <p className="text-grey mt-2 mb-5">
              Please connect your wallet to claim your FanEpack.
            </p>
            <ConnectButton />
          </div>
        </>
      );
    } else {
      return (
        <>
          <div
            className={classnames(
              'inline-block align-bottom bg-dark1 rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:w-full sm:max-w-lg p-5 text-center'
            )}
          >
            <h3 className="text-3xl font-bold text-white">
              Install MetaMask to Connect Your Wallet
            </h3>
            <p className="text-grey mt-2 mb-5">
              Please connect your wallet to claim your FanEpack.
            </p>
            <InstallButton />
          </div>
        </>
      );
    }
  } else {
    return (
      <div
        className={classnames(
          'inline-block align-bottom bg-dark1 rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:w-full sm:max-w-lg'
        )}
      >
        <div className="bg-dark1 px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
          <div className="flex flex-col">
            <div className="flex flex-row items-center justify-between">
              <h3 className="text-3xl leading-6 font-bold text-white">
                Claim FanEpack
              </h3>
              <Button
                className="flex items-center justify-center w-[2.5rem] h-[2.5rem] rounded-full p-1"
                variant="Outline"
                onClick={() => p.onCancel()}
              >
                <IoCloseOutline className="text-2xl" />
              </Button>
            </div>
            <div className="mt-10">
              <form onSubmit={handleSubmit}>
                <div className="flex items-center">
                  <div className="flex items-center justify-center w-[2.2rem] h-[2.2rem] rounded-full bg-[#20332e]">
                    <IoCheckmark className="text-xl text-[#30B28C]" />
                  </div>
                  <div className="flex items-center text-lg font-semibold ml-2">
                    <span className="text-gray-500 mr-1">
                      Wallet Connected:
                    </span>
                    <span className="text-white">
                      {wallet?.slice(0, 6)}...{wallet?.slice(-4)}
                    </span>
                  </div>
                </div>
                <div className="text-base text-gray-500 font-semibold mt-8">
                  MetaFans holders are eligible to claim FanEpack NFTs.
                </div>
                <div className="text-base text-gray-500 font-semibold mt-2">
                  You have claimed{' '}
                  <span className="text-white font-bold">{claims.claimed}</span>{' '}
                  out of{' '}
                  <span className="text-white font-bold">{claims.earned}</span>{' '}
                  NFTs.
                </div>
                <Input
                  type="tel"
                  className="mt-8"
                  value={quantity}
                  onChange={(ev) => setQuantity(ev.currentTarget.value)}
                />
                <div className="mt-8">
                  <Button
                    type="submit"
                    className="w-full font-semibold"
                    variant="GradientOutline"
                    disabled={!!errorMessage}
                  >
                    Claim
                  </Button>
                </div>
                {errorMessage && (
                  <div className="text-center text-red-500 font-semibold mt-5">
                    {errorMessage}
                  </div>
                )}
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
