import React from 'react';
import { isDesktop } from 'react-device-detect';
import ConnectButton from '../ConnectButton/ConnectButton';
import InstallButton from '../InstallButton/InstallButton';
import OpensaleButton from '../OpensaleButton/OpensaleButton';
import WrongChainButton from '../WrongChainButton/WrongChainButton';

import env from '../../data/env';

import {
  useChain,
  useTotalSupply,
  useWallet,
} from '../../hooks/useEventStates';

const PrimaryActionButton = () => {
  const account = useWallet();
  const chainId = useChain();
  const totalSupply = useTotalSupply();

  React.useEffect(() => {
    console.log(isDesktop);
  }, [isDesktop]);

  if (!(account && chainId)) {
    if (!isDesktop) {
      return <ConnectButton />;
    }

    const isMetaMaskInstalled = !!window.ethereum?.isMetaMask;

    if (isMetaMaskInstalled) {
      return <ConnectButton />;
    }

    return <InstallButton />;
  }

  const isSupportedChain = chainId === env.chainId;

  if (!isSupportedChain) {
    return <WrongChainButton />;
  }

  if ((totalSupply ?? 0) >= env.nftMaxSupply) {
    return <div>Sold Out</div>;
  }

  return <OpensaleButton />;
};

export default PrimaryActionButton;
