import { providers } from 'ethers';

import env from '../../data/env';
import dispatchEvent from '../common/dispatchEvent';

const globalProvider = {
  connect: function () {
    let provider;

    // TODO: use web sockets in mainnet - more testing needed

    // if (env.chainId === 4) {
    //   provider = new WebSocketProvider(`wss://rinkeby.infura.io/ws/v3/${env.infuraKey}`);
    // } else {
    provider = new providers.InfuraProvider(env.network, env.infuraKey);
    // }

    dispatchEvent('providerChanged', provider);
  },
};

export default globalProvider;
