import React from 'react';
import Slider from 'react-slick';
import Button from '../../button/Button';
import dispatchEvent from '../../../fns/common/dispatchEvent';

import slide1 from '../../../assets/images/F1EVENT.jpg';
import slide2 from '../../../assets/images/hockeytix.jpg';
import slide3 from '../../../assets/images/gamefans.jpg';
import slide4 from '../../../assets/images/closeups.jpg';

const Join = () => {
  const sliderSettings = {
    dots: true,
    arrows: false,
    infinite: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: true,
    autoplay: true,
    autoplaySpeed: 2000,
    infinite: true,
  };
  return (
    <section className="mb-10 join-metafans" id="join">
      <div className="px-5 md:px-10">
        <div className="flex flex-col items-start md:flex-row">
          <div className="w-full md:w-5/12 md:mr-5">
            <h1 className="mb-5 mr-5 text-3xl font-bold leading-tight lt-white md:text-4xl">
              Join the MetaFans Community
            </h1>
            <h2 className="pb-5 mb-4 text-base font-semibold text-grey md:text-lg">
              Connect with our ultimate sports &amp; poker community & enjoy special group events for live sports, live poker tournaments & Special MetaFans events throughout the year.
            </h2>
            <Button
              variant="Primary"
              className=""
              onClick={() => dispatchEvent('opensaleModalRequested')}
            >
              Mint a MetaFan NFT
            </Button>
          </div>
          <div className="relative w-full mb-10 md:w-7/12 utilities-vert-slider lg:pl-20">
            <Slider {...sliderSettings}>
              <img className="object-cover rounded-3xl" src={slide1} alt="metafans photos"></img>
              <img className="object-cover rounded-3xl" src={slide2} alt="irl metafans photos"></img>
              <img className="object-cover rounded-3xl" src={slide3} alt="irl metafans photos"></img>
              <img className="object-cover rounded-3xl" src={slide4} alt="irl metafans photos"></img>
            </Slider>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Join;
