import React from 'react';
import DocumentMeta from 'react-document-meta';
import '../../styles/app.css';
import '../../styles/metafans.scss';
import bghtw from '../../assets/images/bghtw1.jpg';
import Faqs from '../sections/Home/FAQs';


function Faq() {

    const meta = {
        title: 'FAQ | MetaFans NFT',
        description:
            'Committed to bringing people together, providing unforgettable IRL experiences. Learn about the MetaFans and our supported charities',
    };
    return (
        <>
            <DocumentMeta {...meta}></DocumentMeta>
            <div className="theme-dk-blue" style={{ backgroundImage: `url(${bghtw})`, backgroundSize: 'cover', backgroundPosition: 'center' }}>
                <div className="container px-16 py-8 mx-auto my-8 text-white bg-gray-900/50" style={{ borderRadius: '20px', border: '1px solid #ffffff1c' }}>
                    {/* <h1 className='mt-5 mb-16 text-4xl font-bold tracking-tight text-center lt-white md:text-5xl'>
                       FAQ's
                    </h1> */}
                    <Faqs />
                    {/* <h3 className='mb-5 text-lg font-bold tracking-tight text-white/80 md:text-xl'>
                        What is an NFT?
                    </h3>
                    <p className='mt-4 mb-4 text-base text-white/80'>
                        An NFT, or non-fungible token, is a unique digital item you can buy, sell, or own. Think of it like a rare
                        trading card, but in digital form. Each NFT is one-of-a-kind& has a special code, making it different
                        from other digital items. So, even though digital art or music can be copied, the NFT proves who is the
                        original owner.
                    </p>
                    <h3 className='mt-8 mb-2 text-lg font-bold tracking-tight text-white/80 md:text-xl'>
                        How to create a wallet and install MetaMask?
                    </h3>
                    <p className='mt-4 mb-4 text-base text-white/80'>
                        Visit <a href='https://metamask.io/' target='_blank'>https://metamask.io/</a> Hit &quot;Download&quot; in the menu bar to attach MetaMask to your browser and
                        create a wallet with a password you choose. Meta mask will assign your wallet a 12-word phrase to
                        keep your wallet secure. Save it and keep it in a safe place for only you to view. This wallet will allow
                        you to hold cryptocurrency, such as Ethereum which is used to pay for the real money benefits (RMB)
                        offered by the MetaFans sports and poker club.
                    </p>
                    <h3 className='mt-8 mb-2 text-lg font-bold tracking-tight text-white/80 md:text-xl'>
                        What is a set of MetaFans?
                    </h3>
                    <p className='mt-4 mb-4 mb-5 text-base text-white/80'>
                        To own a complete set a holder needs The Jock, The Cheerleader, The Die Hard, and the Average Joe,
                        to get enhanced member benefits!
                    </p>
                    <h3 className='mt-8 mb-2 text-lg font-bold tracking-tight text-white/80 md:text-xl'>
                        What are the Rarities?
                    </h3>
                    <p className='mt-4 mb-4 mb-5 text-base text-white/80'>
                        Each MetaFan is algorithmically generated by distributing 9988 unique assets amongst 4 avatars with
                        varying rarities in 16 categories. Football Jersey, Baseball Jersey, Team hat and Tattoos are 4 category
                        examples.
                    </p>
                    <h3 className='mt-8 mb-2 text-lg font-bold tracking-tight text-white/80 md:text-xl'>
                        How can I trade for other MetaFans to complete a set?
                    </h3>
                    <p className='mt-4 mb-4 mb-5 text-base text-white/80'>
                        Join the MetaFans discord here (make “here” a link to the discord Page)& look for the TRADES
                        channel. Post what you are trading& what you’re looking for& wait for a reply. You may also reach out
                        to CryptoDaddio who will be more than happy and able to trade with you.
                    </p>
                    <h3 className='mt-8 mb-2 text-lg font-bold tracking-tight text-white/80 md:text-xl'>
                        If I do not have a MetaFan NFT, can I still join the poker club?
                    </h3>
                    <p className='mt-4 mb-4 mb-5 text-base text-white/80'>
                        Yes! Join our exclusive Poker Club for only $75/month and receive entry into all our monthly real
                        money Poker tournaments. As a bonus get one square pick each week for all MetaFans sports squares.
                    </p>
                   
                    <h3 className='mt-8 mb-2 text-lg font-bold tracking-tight text-white/80 md:text-xl'>
                        What are MetaFans NFL/College Football square pools?
                    </h3>
                    <p className='mt-4 mb-4 mb-5 text-base text-white/80'>
                        A betting pool where entrants make random picks for the score of the game. These entries are known
                        as &quot;squares&quot; where players either pick or are assigned a cell in a 10 by 10 grid of 100 squares.
                    </p>
                    <h3 className='mt-8 mb-2 text-lg font-bold tracking-tight text-white/80 md:text-xl'>
                        How do I pick my MetaFans NFL/College Football squares?
                    </h3>
                    <p className='mt-4 mb-4 mb-5 text-base text-white/80'>
                        Members of MetaFans NFL/College Football Squares pool will simply login to the website and click the
                        square(s) they want. When signing up on the website use your discord username and email.
                    </p>

                    <h3 className='mt-8 mb-2 text-lg font-bold tracking-tight text-white/80 md:text-xl'>
                        How are the “Y”& “X” axis numbers chosen in an NFL/College Football square pool?
                    </h3>
                    <p className='mt-4 mb-4 mb-5 text-base text-white/80'>
                        After all the squares have a player or the NFL/College game begins, a team is assigned to “X”& “Y”
                        and each box on the axis is assigned at random a number 0-9 so that everyone has a fair chance of
                        winning.
                    </p>
                    <h3 className='mt-8 mb-2 text-lg font-bold tracking-tight text-white/80 md:text-xl'>
                        Other benefits for owning a Metafan?
                    </h3>
                    <p className='mt-4 mb-4 mb-5 text-base text-white/80'>
                        Holders connect with top handicappers for sports. MetaFans offers IRL meetups, concerts, and game
                        tickets as prizes or bonuses to our members. Our discord is also a place to engage with other sports
                        and poker minded individuals to have conversations and root each other’s favorite teams on to victory.
                    </p> */}

                </div>
            </div>
        </>
    );
}

export default Faq;
