import React from 'react';

import affiliate from '../../assets/images/affiliate-banner.jpg';

function Affiliate() {
  return (
    <section className="affiliate">
      <div className="container mx-auto text-center">
        <img
          className="mx-auto"
          src={affiliate}
          alt="metafans affiliate program"
        />
        <h1 className="lt-white font-bold mb-3">
          Join the MetaFans Partner Program!
        </h1>
        <p className="text-grey">
          Talk about MetaFans, get someone to mint with your link and{' '}
          <span className="lt-white font-bold">
            Earn $25 for each new holder that mints a Metafan NFT!
          </span>
        </p>
        <div className="mt-5">
          <p className="text-grey">
            How does it work? It's simple. Signup, get an affiliate link and get
            paid!
          </p>
        </div>
        <div className="mt-10 flex items-center justify-center">
          <div className="login mx-2">
            <a href="https://metafans.everflowclient.io/">Login</a>
          </div>
          <div className="sign-up mx-2">
            <a href="https://metafans.everflowclient.io/affiliate/signup?q=5J473L">
              Signup Now
            </a>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Affiliate;
