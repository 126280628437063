import React, { useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { FiX } from 'react-icons/fi';
import classnames from 'classnames';

import bugger from '../../assets/images/bugger.svg';

const Navbar = () => {
  const [open, setOpen] = useState(false);
  const { pathname } = useLocation();

  return (
    <nav className="pr-20 text-white navbar">
      <div onClick={() => setOpen(!open)} className="nav-icon">
        {open ? <FiX width="20" height="20" /> : <img src={bugger} alt="hamburger" width="24" height="24" />}
      </div>
      <ul className={open ? 'nav-links active text-base' : 'nav-links gap-5 text-base'}>
        <li className="nav-item">
          <a
            href="/"
            className={classnames({
              'nav-active': pathname === '/',
              'nav-link font-medium': pathname !== '/',
            })}
          >
            Sports
          </a>
        </li>
        <li className="nav-item">
          <a
            href="/poker-club"
            className={classnames({
              'nav-active': pathname === '/poker-club',
              'nav-link font-medium': pathname !== '/poker-club',
            })}
          >
            Poker Club
          </a>
        </li>
        <li className="nav-item">
          <a
            href="/about-metafans"
            className={classnames({
              'nav-active': pathname === '/about-metafans',
              'nav-link font-medium': pathname !== '/about-metafans',
            })}
          >
            About MetaFans
          </a>
        </li>
        {/* <li className="nav-item">
          <a
            href="/fun-with-drew"
            className={classnames({
              'nav-active': pathname === '/fun-with-drew',
              'nav-link font-medium': pathname !== '/fun-with-drew',
            })}
          >
            Fun With BetOnDrew
          </a>
        </li> */}

        <li className="nav-item">
          <a
            href="/new-member-benefits"
            className={classnames({
              'nav-active': pathname === '/new-member-benefits',
              'nav-link font-medium': pathname !== '/new-member-benefits',
            })}
          >
           New Member Benefits
          </a>
        </li>
        
        <li className="nav-item">
          <a
            href="/nft-utilities"
            className={classnames({
              'nav-active': pathname === '/nft-utilities',
              'nav-link font-medium': pathname !== '/nft-utilities',
            })}
          >
            NFT & Utilities
          </a>
        </li>
        <li className="block nav-item">
          <a
            href="/faq"
            className={classnames({
              'nav-active': pathname === '/faq',
              'nav-link font-medium': pathname !== '/faq',
            })}
          >
            FAQ
          </a>
        </li>
       
        {/* <li className="block nav-item">
          <a
            href="https://ticket.metafans.com"
            className={classnames({
              'nav-active': pathname === '/tickets',
              'nav-link font-medium': pathname !== '/tickets',
            })}
          >
            Tickets
          </a>
        </li> */}
       
       
      </ul>
    </nav>
  );
};

export default Navbar;