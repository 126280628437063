const errors = {
  soldOut: 'All NFTs have been sold out',
  mintOne: 'Please mint at least one',
  mustBeNumber: 'Must be a number',
  remainingSupply: (remainingSupply) =>
    `Only ${remainingSupply} NFT${remainingSupply === 1 ? '' : 's'} left`,
  perWalletTransactionLimit: (limit) =>
    `Please mint up to ${limit} per transaction`,
  timeLimit: 'Only 1 mint transaction may be submitted every 15 minutes',
};

export default errors;
