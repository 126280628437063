import "react-toastify/dist/ReactToastify.css";
import "./styles/app.css";
import "./styles/metafans.scss";

import React from "react";
import { Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";

import ModalContainer from "./components/modal/ModalContainer";
import Header from "./components/Header/Header.jsx";
import Footer from "./components/Footer/Footer.jsx";
import Home from "./components/views/Home.jsx";
import About from "./components/views/About.jsx";
import Utilities from "./components/views/Utilities.jsx";
import MintPage from "./components/views/MintPage.jsx";
import PrivacyPolicy from "./components/views/PrivacyPolicy.jsx";
import Terms from "./components/views/Terms.jsx";
import Affiliate from "./components/views/Affiliate.jsx";
import ThankYou from "./components/views/ThankYou.jsx";
import TaxForm from "./components/views/TaxForm.jsx";
import ClubhouseLanding from "./components/views/ClubhouseLanding.jsx";
import ComingSoon from "./components/views/ComingSoon.jsx";
import OptIn from "./components/views/OptIn";
import FunWithDrew from "./components/views/FunWithDrew.jsx";
import QualifyWin from "./components/views/QualifyWin.jsx";
import Faq from "./components/views/Faq.jsx";
import AboutMetafans from "./components/views/AboutMetafans.jsx";


function App() {
  return (
    <>
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/who-we-are" element={<About />} />
        <Route path="/about-metafans" element={<AboutMetafans />} />
        <Route path="/poker-club" element={<ComingSoon />} />
        <Route path="/fun-with-drew" element={<FunWithDrew />} />
        <Route path="/new-member-benefits" element={<QualifyWin />} />
        <Route path="/nft-utilities" element={<Utilities />} />
        <Route path="/tickets" element={<MintPage />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/terms" element={<Terms />} />
        <Route path="/affiliate" element={<Affiliate />} />
        <Route path="/thank-you" element={<ThankYou />} />
        <Route path="/tax-form" element={<TaxForm />} />
        <Route path="/faq" element={<Faq />} />
        <Route path="/opt-in" element={<OptIn />} />
      </Routes>
      <Footer />
      <ModalContainer />
      <ToastContainer
        autoClose={30_000}
        hideProgressBar={false}
        closeOnClick={false}
        // toastStyle={{
        //   background: theme.palette.background.paper,
        //   color: theme.palette.text.primary,
        // }}
        theme="dark"
      />
    </>
  );
}

export default App;
